import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "bpmn-common-panel",
    {
      attrs: {
        image: _vm.image,
        "is-read-only": _vm.isReadOnly,
        "width-style": _vm.widthStyle,
      },
      on: { close: _vm.closePanel },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "template",
        { slot: "name-panel" },
        [
          _c(VTextField, {
            attrs: { label: "Pool/Lane (Role) Name", autofocus: "" },
            model: {
              value: _vm.value.name,
              callback: function ($$v) {
                _vm.$set(_vm.value, "name", $$v)
              },
              expression: "value.name",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "edit-property" },
        [
          _c(
            VRadioGroup,
            {
              attrs: { row: "" },
              model: {
                value: _vm.isRoleDef,
                callback: function ($$v) {
                  _vm.isRoleDef = $$v
                },
                expression: "isRoleDef",
              },
            },
            [
              _c(VRadio, {
                attrs: {
                  id: "roleDef",
                  name: "roleDef",
                  value: "Human",
                  label: "Human",
                },
              }),
              _c(VRadio, {
                attrs: {
                  id: "roleDef",
                  name: "roleDef",
                  value: "System",
                  label: "System",
                },
              }),
            ],
            1
          ),
          _vm.isRoleDef == "Human"
            ? _c(
                "div",
                [
                  _c(
                    VRadioGroup,
                    {
                      staticStyle: { "margin-top": "0px !important" },
                      attrs: { row: "" },
                      model: {
                        value: _vm.isRoleResolution,
                        callback: function ($$v) {
                          _vm.isRoleResolution = $$v
                        },
                        expression: "isRoleResolution",
                      },
                    },
                    [
                      _c(VRadio, {
                        staticStyle: {
                          "margin-right": "8px !important",
                          "font-size": "15px",
                        },
                        attrs: {
                          id: "roleResolution",
                          name: "roleResolution",
                          value: "null",
                          label: "None",
                        },
                      }),
                      _c(VRadio, {
                        staticStyle: {
                          "margin-right": "8px !important",
                          "font-size": "15px",
                        },
                        attrs: {
                          id: "roleResolution",
                          name: "roleResolution",
                          value:
                            "org.uengine.five.overriding.IAMRoleResolutionContext",
                          label: "Role Resolution By IAM Scope",
                        },
                      }),
                      _c(VRadio, {
                        staticStyle: {
                          "margin-right": "8px !important",
                          "font-size": "15px",
                        },
                        attrs: {
                          id: "roleResolution",
                          name: "roleResolution",
                          value:
                            "org.uengine.kernel.DirectRoleResolutionContext",
                          label: "Role Resolution By Direct user",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.value.roleResolutionContext &&
                  _vm.value.roleResolutionContext._type ==
                    "org.uengine.five.overriding.IAMRoleResolutionContext"
                    ? _c(VTextField, {
                        attrs: { label: "Scope Name" },
                        model: {
                          value: _vm.value.roleResolutionContext.scope,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.value.roleResolutionContext,
                              "scope",
                              $$v
                            )
                          },
                          expression: "value.roleResolutionContext.scope",
                        },
                      })
                    : _vm._e(),
                  _vm.value.roleResolutionContext &&
                  _vm.value.roleResolutionContext._type ==
                    "org.uengine.kernel.DirectRoleResolutionContext"
                    ? _c(VTextField, {
                        attrs: { label: "User ID" },
                        model: {
                          value: _vm.value.roleResolutionContext.endpoint,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.value.roleResolutionContext,
                              "endpoint",
                              $$v
                            )
                          },
                          expression: "value.roleResolutionContext.endpoint",
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.isRoleDef == "System"
            ? _c(
                "div",
                [
                  _c(VSelect, {
                    attrs: {
                      items: _vm.serviceIdList,
                      label: "Select serviceId from eureka",
                    },
                    model: {
                      value: _vm.value.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "name", $$v)
                      },
                      expression: "value.name",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }