var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.status == "Failed"
      ? _c("div", [
          _vm.type == "StartEvent" ||
          _vm.type == "EndEvent" ||
          _vm.type == "IntermediateEvent" ||
          _vm.type == "Gateway"
            ? _c(
                "div",
                [
                  _c(
                    "image-element",
                    {
                      attrs: {
                        image: _vm.failed_image,
                        "sub-width": "20px",
                        "sub-height": "20px",
                        "sub-right": "5px",
                        "sub-bottom": "5px",
                      },
                    },
                    [
                      _c(
                        "md-tooltip",
                        { attrs: { "md-direction": "bottom" } },
                        [_vm._v("My tooltip")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "image-element",
                    {
                      attrs: {
                        image: _vm.failed_image,
                        "sub-width": "20px",
                        "sub-height": "20px",
                        "sub-right": "6%",
                        "sub-top": "5px",
                      },
                    },
                    [
                      _c(
                        "md-tooltip",
                        { attrs: { "md-direction": "bottom" } },
                        [_vm._v("My tooltip")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ])
      : _vm._e(),
    _vm.status == "Stopped"
      ? _c("div", [
          _vm.type == "StartEvent" ||
          _vm.type == "EndEvent" ||
          _vm.type == "IntermediateEvent" ||
          _vm.type == "Gateway"
            ? _c(
                "div",
                [
                  _c("image-element", {
                    attrs: {
                      image: _vm.stopped_image,
                      "sub-width": "20px",
                      "sub-height": "20px",
                      "sub-right": "5px",
                      "sub-bottom": "5px",
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("image-element", {
                    attrs: {
                      image: _vm.stopped_image,
                      "sub-width": "20px",
                      "sub-height": "20px",
                      "sub-right": "6%",
                      "sub-top": "5px",
                    },
                  }),
                ],
                1
              ),
        ])
      : _vm._e(),
    _vm.status == "Completed"
      ? _c("div", [
          _vm.type == "StartEvent" ||
          _vm.type == "EndEvent" ||
          _vm.type == "IntermediateEvent" ||
          _vm.type == "Gateway"
            ? _c(
                "div",
                [
                  _c(
                    "image-element",
                    {
                      attrs: {
                        image: _vm.complete_image,
                        "sub-width": "20px",
                        "sub-height": "20px",
                        "sub-right": "5px",
                        "sub-bottom": "5px",
                      },
                    },
                    [
                      _c("md-tooltip", { attrs: { "md-direction": "right" } }, [
                        _vm._v("My tooltip"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("image-element", {
                    attrs: {
                      image: _vm.complete_image,
                      "sub-width": "20px",
                      "sub-height": "20px",
                      "sub-right": "6%",
                      "sub-top": "5px",
                    },
                  }),
                ],
                1
              ),
        ])
      : _vm._e(),
    _vm.status == "Running"
      ? _c(
          "div",
          [
            _vm.type == "StartEvent" ||
            _vm.type == "EndEvent" ||
            _vm.type == "IntermediateEvent" ||
            _vm.type == "Gateway"
              ? _c(
                  "div",
                  [
                    _c("image-element", {
                      attrs: {
                        image: _vm.running_image,
                        "sub-width": "20px",
                        "sub-height": "20px",
                        "sub-right": "-10px",
                        "sub-bottom": "-5px",
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c("image-element", {
                      attrs: {
                        image: _vm.running_image,
                        "sub-width": "20px",
                        "sub-height": "20px",
                        "sub-align": "center",
                        "sub-top": "0px",
                      },
                    }),
                  ],
                  1
                ),
            _c(
              _vm.type == "StartEvent" ||
                _vm.type == "EndEvent" ||
                _vm.type == "IntermediateEvent" ||
                _vm.type == "Gateway"
                ? "circle-element"
                : "rectangle-element",
              {
                tag: "component",
                attrs: {
                  "sub-z-index": -1,
                  "sub-width": "120%",
                  "sub-height": "120%",
                  "sub-right": "-10%",
                  "sub-bottom": "-10%",
                  "sub-style": _vm.animationStyle,
                },
              }
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }