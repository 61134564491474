var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loopType == "Standard"
        ? _c("image-element", {
            attrs: {
              image: _vm.loop_standard_image,
              "sub-width": "15px",
              "sub-height": "15px",
              "sub-bottom": "5px",
              "sub-align": "center",
            },
          })
        : _vm._e(),
      _vm.loopType == "MIParallel"
        ? _c(
            "geometry-element",
            {
              attrs: {
                "sub-width": "15px",
                "sub-height": "15px",
                "sub-bottom": "5px",
                "sub-align": "center",
              },
            },
            [
              _c("geometry-line", { attrs: { from: [0, 30], to: [0, 0] } }),
              _c("geometry-line", { attrs: { from: [15, 30], to: [15, 0] } }),
              _c("geometry-line", { attrs: { from: [30, 30], to: [30, 0] } }),
            ],
            1
          )
        : _vm._e(),
      _vm.loopType == "MISequential"
        ? _c(
            "geometry-element",
            {
              attrs: {
                "sub-width": "15px",
                "sub-height": "15px",
                "sub-bottom": "5px",
                "sub-align": "center",
              },
            },
            [
              _c("geometry-line", { attrs: { from: [0, 30], to: [30, 30] } }),
              _c("geometry-line", { attrs: { from: [0, 15], to: [30, 15] } }),
              _c("geometry-line", { attrs: { from: [0, 0], to: [30, 0] } }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }