var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "bpmn-common-panel",
    {
      attrs: {
        image: _vm.image,
        "is-read-only": _vm.isReadOnly,
        "width-style": _vm.widthStyle,
      },
      on: { close: _vm.closePanel },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c("template", { slot: "edit-property" }, [
        _c(
          "div",
          [
            _c("label", [_vm._v("Array Variable for multiplicity")]),
            _c("bpmn-variable-selector", {
              attrs: { definition: _vm.canvas.value },
              model: {
                value: _vm.value,
                callback: function ($$v) {
                  _vm.value = $$v
                },
                expression: "value",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("label", [_vm._v("Variable Mappings")]),
            _c("bpmn-parameter-contexts", {
              attrs: {
                parameterContexts: _vm.value.variableBindings,
                forSubProcess: true,
              },
              model: {
                value: _vm.value,
                callback: function ($$v) {
                  _vm.value = $$v
                },
                expression: "value",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }