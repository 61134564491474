import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.data.definition
        ? _c(
            "opengraph",
            {
              ref: "opengraph",
              attrs: {
                "focus-canvas-on-select": "",
                wheelScalable: "",
                dragPageMovable: _vm.dragPageMovable,
                enableContextmenu: false,
                enableRootContextmenu: false,
                enableHotkeyCtrlC: false,
                enableHotkeyCtrlV: false,
                enableHotkeyDelete: false,
                slider: false,
                movable: !_vm.monitor,
                resizable: !_vm.monitor,
                selectable: !_vm.monitor,
                connectable: !_vm.monitor,
                width: 1500,
                height: 1000,
              },
              on: {
                canvasReady: _vm.bpmnReady,
                userAction: _vm.onUserAction,
                connectShape: _vm.onConnectShape,
                removeShape: _vm.onRemoveShape,
                divideLane: _vm.onDivideLane,
                beforeDestroyElement: _vm.onBeforeDestroyElement,
              },
            },
            [
              _vm._l(_vm.data.definition.roles, function (role, idx) {
                return _c(
                  "div",
                  { key: idx },
                  [
                    role != null
                      ? _c("bpmn-role", { attrs: { role: role } })
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _vm._l(
                _vm.data.definition.childActivities[1],
                function (activity, idx) {
                  return _c(
                    "div",
                    { key: idx },
                    [
                      activity != null
                        ? _c(_vm.getComponentByClassName(activity._type), {
                            tag: "component",
                            attrs: {
                              activity: activity,
                              definition: _vm.data.definition,
                              status: activity.status,
                              faultMessage: activity.faultMessage,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }
              ),
              _vm._l(
                _vm.data.definition.sequenceFlows,
                function (relation, idx) {
                  return _c(
                    "div",
                    { key: idx },
                    [
                      relation != null
                        ? _c("bpmn-relation", {
                            attrs: {
                              relation: relation,
                              definition: _vm.data.definition,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }
              ),
              _vm._l(
                _vm.data.definition.messageFlows,
                function (relation, idx) {
                  return _c(
                    "div",
                    { key: idx },
                    [
                      relation != null
                        ? _c("bpmn-message-flow", {
                            attrs: {
                              relation: relation,
                              definition: _vm.data.definition,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }
              ),
            ],
            2
          )
        : _vm._e(),
      _vm.data.definition
        ? _c("bpmn-component-changer", {
            attrs: { data: _vm.componentChangerData },
          })
        : _vm._e(),
      _vm.data.definition
        ? _c(
            VDialog,
            {
              attrs: { "max-width": "900" },
              model: {
                value: _vm.processVariablesDialog,
                callback: function ($$v) {
                  _vm.processVariablesDialog = $$v
                },
                expression: "processVariablesDialog",
              },
            },
            [
              _c(
                VCard,
                [
                  _c(VCardTitle, [_vm._v("Process Variables")]),
                  _c(
                    VCardText,
                    [
                      _c("bpmn-object-grid", {
                        attrs: { "full-fledged": true },
                        model: {
                          value: _vm.processVariables,
                          callback: function ($$v) {
                            _vm.processVariables = $$v
                          },
                          expression: "processVariables",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCardActions,
                    [
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          attrs: { color: "primary", text: "" },
                          on: { click: _vm.closeProcessVariables },
                        },
                        [_vm._v("Close")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.data.definition
        ? _c(
            VDialog,
            {
              ref: "definitionSettings",
              attrs: { "max-width": "320" },
              model: {
                value: _vm.definitionSettings,
                callback: function ($$v) {
                  _vm.definitionSettings = $$v
                },
                expression: "definitionSettings",
              },
            },
            [
              _c(
                VCard,
                [
                  _c(VCardTitle, [_vm._v("Definition Settings")]),
                  _c(
                    VCardText,
                    [
                      _c(
                        VForm,
                        {
                          attrs: { novalidate: "" },
                          on: {
                            submit: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.submit.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            VContainer,
                            [
                              _c(VTextField, {
                                attrs: { label: "Description" },
                                model: {
                                  value:
                                    _vm.defintionSettings.shortDescription.text,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.defintionSettings.shortDescription,
                                      "text",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "defintionSettings.shortDescription.text",
                                },
                              }),
                              _c(VTextField, {
                                attrs: { label: "Instance Name Pattern" },
                                model: {
                                  value:
                                    _vm.data.definition.instanceNamePattern,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.data.definition,
                                      "instanceNamePattern",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "data.definition.instanceNamePattern",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(VSwitch, {
                            attrs: {
                              id: "my-test1",
                              name: "my-test1",
                              color: "primary",
                              label: "Initiate by event",
                            },
                            model: {
                              value:
                                _vm.data.definition.initiateByFirstWorkitem,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.data.definition,
                                  "initiateByFirstWorkitem",
                                  $$v
                                )
                              },
                              expression:
                                "data.definition.initiateByFirstWorkitem",
                            },
                          }),
                          _c(VSwitch, {
                            attrs: {
                              id: "my-test1",
                              name: "my-test1",
                              color: "primary",
                              label: " Volatile",
                            },
                            model: {
                              value: _vm.data.definition.volatile,
                              callback: function ($$v) {
                                _vm.$set(_vm.data.definition, "volatile", $$v)
                              },
                              expression: "data.definition.volatile",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    VCardActions,
                    [
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          attrs: { color: "primary", text: "" },
                          on: { click: _vm.closeDefinitionSettings },
                        },
                        [_vm._v("Close")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }