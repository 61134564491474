import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VLayout,
    { attrs: { wrap: "" } },
    [
      _c(
        VNavigationDrawer,
        {
          staticStyle: { width: "600px" },
          attrs: { absolute: "", permanent: "", right: "" },
        },
        [
          _c(
            VList,
            { staticClass: "pa-1" },
            [
              _c(
                VListItem,
                [
                  _vm.value._instanceInfo
                    ? _c(
                        VTabs,
                        [
                          _vm.value._instanceInfo
                            ? _c(
                                VTab,
                                [
                                  _c(VListItemTitle, [
                                    _vm._v("Instance Info"),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    VTabs,
                    {
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    _vm._l(_vm.tabs, function (tab) {
                      return _c(
                        VTab,
                        {
                          key: tab,
                          attrs: { id: tab.toLowerCase() + _vm._uid },
                        },
                        [_c(VListItemTitle, [_vm._v(_vm._s(tab))])],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: { icon: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.closePanel()
                        },
                      },
                    },
                    [
                      _c(VIcon, { attrs: { color: "grey lighten-1" } }, [
                        _vm._v("mdi-close"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.value._instanceInfo
            ? _c(VSimpleTable, {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function () {
                        return [
                          _c("thead", [
                            _c("tr", [
                              _c("th", { staticClass: "text-left" }, [
                                _vm._v("Key"),
                              ]),
                              _c("th", { staticClass: "text-left" }, [
                                _vm._v("Value"),
                              ]),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(
                              _vm.value._instanceInfo,
                              function (row, index) {
                                return _c("tr", { key: index }, [
                                  _c("td", [_vm._v(_vm._s(index))]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm.value._instanceInfo[index])
                                    ),
                                  ]),
                                ])
                              }
                            ),
                            0
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1701337627
                ),
              })
            : _vm._e(),
          _c(
            VList,
            { staticClass: "pt-0", attrs: { dense: "", flat: "" } },
            [
              _c(VDivider),
              _vm.tab == 0
                ? _c(
                    "div",
                    { staticStyle: { "margin-top": "10px" } },
                    [
                      _c(
                        VCard,
                        { attrs: { flat: "" } },
                        [
                          _c(
                            VCardText,
                            [
                              _vm.tracingTag !== null
                                ? _c(VTextField, {
                                    attrs: {
                                      label: "액티비티 ID*",
                                      counter: "50",
                                    },
                                    model: {
                                      value: _vm.tracingTag,
                                      callback: function ($$v) {
                                        _vm.tracingTag = $$v
                                      },
                                      expression: "tracingTag",
                                    },
                                  })
                                : _vm._e(),
                              _vm.value.condition._type ==
                                "org.uengine.kernel.Evaluate" ||
                              _vm.value.condition._type ==
                                "org.uengine.five.kernel.LoopCountEvaluate"
                                ? _c(
                                    "div",
                                    [
                                      _c(VTextField, {
                                        attrs: {
                                          label: "Sequence Name",
                                          autofocus: "",
                                        },
                                        model: {
                                          value: _vm.value.name,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.value, "name", $$v)
                                          },
                                          expression: "value.name",
                                        },
                                      }),
                                      _c(VCheckbox, {
                                        attrs: { label: "Otherwise Condition" },
                                        model: {
                                          value: _vm.isOtherwise,
                                          callback: function ($$v) {
                                            _vm.isOtherwise = $$v
                                          },
                                          expression: "isOtherwise",
                                        },
                                      }),
                                      _c(VTextField, {
                                        attrs: {
                                          label: "Priority",
                                          type: "number",
                                        },
                                        model: {
                                          value: _vm.value.priority,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.value, "priority", $$v)
                                          },
                                          expression: "value.priority",
                                        },
                                      }),
                                      _c(VCheckbox, {
                                        attrs: { label: "Complex Rule" },
                                        model: {
                                          value: _vm.isComplexCondition,
                                          callback: function ($$v) {
                                            _vm.isComplexCondition = $$v
                                          },
                                          expression: "isComplexCondition",
                                        },
                                      }),
                                      _vm.isComplexCondition
                                        ? _c("org-uengine-kernel-Or", {
                                            attrs: {
                                              definition: _vm.definition,
                                            },
                                            model: {
                                              value: _vm.value.condition,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.value,
                                                  "condition",
                                                  $$v
                                                )
                                              },
                                              expression: "value.condition",
                                            },
                                          })
                                        : _c("org-uengine-kernel-Evaluate", {
                                            attrs: {
                                              definition: _vm.definition,
                                            },
                                            model: {
                                              value: _vm.value.condition,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.value,
                                                  "condition",
                                                  $$v
                                                )
                                              },
                                              expression: "value.condition",
                                            },
                                          }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.value.condition._type ==
                              "org.uengine.kernel.ExpressionEvaluateCondition"
                                ? _c(
                                    "div",
                                    [
                                      _c(VTextField, {
                                        attrs: {
                                          label: "릴레이션 이름",
                                          autofocus: "",
                                        },
                                        model: {
                                          value: _vm.value.name,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.value, "name", $$v)
                                          },
                                          expression: "value.name",
                                        },
                                      }),
                                      _c(VCheckbox, {
                                        attrs: { label: "Otherwise Condition" },
                                        model: {
                                          value: _vm.isOtherwise,
                                          callback: function ($$v) {
                                            _vm.isOtherwise = $$v
                                          },
                                          expression: "isOtherwise",
                                        },
                                      }),
                                      !_vm.isOtherwise
                                        ? _c(VTextField, {
                                            attrs: { label: "컨디션" },
                                            model: {
                                              value:
                                                _vm.value.condition
                                                  .conditionExpression,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.value.condition,
                                                  "conditionExpression",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "value.condition.conditionExpression",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.value._type ==
                              "org.uengine.kernel.bpmn.MessageFlow"
                                ? _c(
                                    "div",
                                    [
                                      _c(VTextField, {
                                        attrs: {
                                          label: "Message Flow Name",
                                          autofocus: "",
                                        },
                                        model: {
                                          value: _vm.value.name,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.value, "name", $$v)
                                          },
                                          expression: "value.name",
                                        },
                                      }),
                                      _c(VSwitch, {
                                        attrs: { label: "Local Call" },
                                        model: {
                                          value: _vm.value.localCall,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.value,
                                              "localCall",
                                              $$v
                                            )
                                          },
                                          expression: "value.localCall",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tab == 1
                ? _c(
                    "div",
                    [
                      _c(
                        VCard,
                        [
                          _c("bpmn-mapper", {
                            attrs: {
                              drawer: _vm.drawer,
                              definition: _vm.definition,
                            },
                            on: {
                              "update:drawer": function ($event) {
                                _vm.drawer = $event
                              },
                            },
                            model: {
                              value: _vm.value,
                              callback: function ($$v) {
                                _vm.value = $$v
                              },
                              expression: "value",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tab == 2
                ? _c(
                    "div",
                    [
                      _c(
                        VCard,
                        { attrs: { flat: "" } },
                        [
                          _c(
                            VCardText,
                            [
                              _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c(VTextField, {
                                        attrs: { label: "x", type: "number" },
                                        model: {
                                          value: _vm.x,
                                          callback: function ($$v) {
                                            _vm.x = $$v
                                          },
                                          expression: "x",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c(VTextField, {
                                        attrs: { label: "y", type: "number" },
                                        model: {
                                          value: _vm.y,
                                          callback: function ($$v) {
                                            _vm.y = $$v
                                          },
                                          expression: "y",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c(VTextField, {
                                        attrs: {
                                          label: "width",
                                          type: "number",
                                        },
                                        model: {
                                          value: _vm.width,
                                          callback: function ($$v) {
                                            _vm.width = $$v
                                          },
                                          expression: "width",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c(VTextField, {
                                        attrs: {
                                          label: "height",
                                          type: "number",
                                        },
                                        model: {
                                          value: _vm.height,
                                          callback: function ($$v) {
                                            _vm.height = $$v
                                          },
                                          expression: "height",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._l(_vm.style, function (item, idx) {
                                return _c(VTextField, {
                                  key: idx,
                                  attrs: { label: item.key },
                                  model: {
                                    value: _vm.style[idx].value,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.style[idx], "value", $$v)
                                    },
                                    expression: "style[idx].value",
                                  },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }