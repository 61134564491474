import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "bpmn-common-panel",
    {
      attrs: {
        image: _vm.image,
        "is-read-only": _vm.isReadOnly,
        "width-style": _vm.widthStyle,
      },
      on: { close: _vm.closePanel },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "template",
        { slot: "edit-property" },
        [
          _vm.value._type ==
          "org.uengine.kernel.bpmn.MessageIntermediateCatchEvent"
            ? _c(
                "div",
                [
                  _c(VTextField, {
                    attrs: { label: "Service Path" },
                    model: {
                      value: _vm.value.servicePath,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "servicePath", $$v)
                      },
                      expression: "value.servicePath",
                    },
                  }),
                  _c(VTextField, {
                    attrs: { label: "Correlation Key" },
                    model: {
                      value: _vm.value.correlationKey,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "correlationKey", $$v)
                      },
                      expression: "value.correlationKey",
                    },
                  }),
                  _c(VTextField, {
                    attrs: { label: "Data for Output" },
                    model: {
                      value: _vm.value.dataOutput.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.value.dataOutput, "name", $$v)
                      },
                      expression: "value.dataOutput.name",
                    },
                  }),
                  _c("label", [_vm._v("Data Mapping")]),
                  _c("bpmn-parameter-contexts", {
                    attrs: {
                      "parameter-contexts": _vm.value.dataOutputMapping,
                      definition: _vm.definition,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.value._type == "org.uengine.kernel.bpmn.ConditionalCatchEvent"
            ? _c(
                "div",
                [
                  _c("label", [_vm._v("Condition")]),
                  _c(VCheckbox, {
                    attrs: { label: "Complex Rule" },
                    model: {
                      value: _vm.complexCondition,
                      callback: function ($$v) {
                        _vm.complexCondition = $$v
                      },
                      expression: "complexCondition",
                    },
                  }),
                  _vm.complexCondition
                    ? _c(
                        "div",
                        [
                          _c("org-uengine-kernel-Or", {
                            attrs: { definition: _vm.definition },
                            model: {
                              value: _vm.value.condition,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "condition", $$v)
                              },
                              expression: "value.condition",
                            },
                          }),
                        ],
                        1
                      )
                    : _c("org-uengine-kernel-Evaluate", {
                        attrs: { definition: _vm.definition },
                        model: {
                          value: _vm.value.condition,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "condition", $$v)
                          },
                          expression: "value.condition",
                        },
                      }),
                  _c(VTextField, {
                    attrs: {
                      label: "Polling Interval (in seconds)",
                      type: "number",
                    },
                    model: {
                      value: _vm.value.pollingIntervalInSecond,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "pollingIntervalInSecond", $$v)
                      },
                      expression: "value.pollingIntervalInSecond",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.value._type ==
          "org.uengine.kernel.bpmn.MessageIntermediateThrowEvent"
            ? _c(
                "div",
                [
                  _c(VTextField, {
                    attrs: { label: "Target Service Pool", readonly: "" },
                    model: {
                      value: _vm.value.role.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.value.role, "name", $$v)
                      },
                      expression: "value.role.name",
                    },
                  }),
                  _c(VTextField, {
                    attrs: { label: "호출 URI 패턴" },
                    model: {
                      value: _vm.value.uriTemplate,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "uriTemplate", $$v)
                      },
                      expression: "value.uriTemplate",
                    },
                  }),
                  _vm.value.uriTemplate &&
                  _vm.value.uriTemplate.indexOf("https://") == 0
                    ? _c(VCheckbox, {
                        attrs: { label: "Don't validate the certificate" },
                        model: {
                          value: _vm.value.noValidationForSSL,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "noValidationForSSL", $$v)
                          },
                          expression: "value.noValidationForSSL",
                        },
                      })
                    : _vm._e(),
                  _vm.links
                    ? _c(VSelect, {
                        attrs: {
                          items: _vm.links,
                          "item-text": "key",
                          "item-value": "href",
                          label: "호출 서비스 선택",
                        },
                        on: { change: _vm.giveJSONHint },
                      })
                    : _vm._e(),
                  _c(VSelect, {
                    attrs: { items: _vm.methodList, label: "호출 메서드" },
                    model: {
                      value: _vm.value.method,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "method", $$v)
                      },
                      expression: "value.method",
                    },
                  }),
                  "GET,DELETE".indexOf(_vm.value.method) == -1
                    ? _c(
                        "div",
                        [
                          _c("label", [_vm._v("Input")]),
                          _c(VSwitch, {
                            attrs: { label: "Use JSON Template" },
                            model: {
                              value: _vm.value.useJSONTemplate,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "useJSONTemplate", $$v)
                              },
                              expression: "value.useJSONTemplate",
                            },
                          }),
                          _vm.useJSONTemplate
                            ? _c(VTextarea, {
                                attrs: { label: "입력 데이터 (JSON template)" },
                                model: {
                                  value: _vm.value.uriTemplate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.value, "uriTemplate", $$v)
                                  },
                                  expression: "value.uriTemplate",
                                },
                              })
                            : _c("bpmn-variable-selector", {
                                staticStyle: { width: "100%", height: "80px" },
                                attrs: { definition: _vm.canvas.value },
                                model: {
                                  value: _vm.inputVariable,
                                  callback: function ($$v) {
                                    _vm.inputVariable = $$v
                                  },
                                  expression: "inputVariable",
                                },
                              }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("label", [_vm._v("Headers")]),
                  _vm._l(_vm.value.headers, function (header, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c(VTextField, {
                          attrs: { label: "Name" },
                          model: {
                            value: _vm.value.headers[index].name,
                            callback: function ($$v) {
                              _vm.$set(_vm.value.headers[index], "name", $$v)
                            },
                            expression: "value.headers[index].name",
                          },
                        }),
                        _c(VTextField, {
                          attrs: { label: "Value" },
                          model: {
                            value: _vm.value.headers[index].value,
                            callback: function ($$v) {
                              _vm.$set(_vm.value.headers[index], "value", $$v)
                            },
                            expression: "value.headers[index].value",
                          },
                        }),
                        _c(
                          VBtn,
                          {
                            on: {
                              click: function ($event) {
                                return _vm.deleteHeader(index)
                              },
                            },
                          },
                          [_vm._v("delete")]
                        ),
                      ],
                      1
                    )
                  }),
                  _c("br"),
                  _c(
                    VBtn,
                    {
                      staticClass: "my-5",
                      attrs: { color: "primary" },
                      on: { click: _vm.addHeader },
                    },
                    [_vm._v("Add header")]
                  ),
                  _c("br"),
                  _c("label", [_vm._v("Output")]),
                  _c("bpmn-parameter-contexts", {
                    attrs: {
                      "parameter-contexts": _vm.value.outputMapping,
                      definition: _vm.definition,
                    },
                  }),
                  _c(VCheckbox, {
                    attrs: {
                      label: "리소스 없을 경우 (404) 오류 처리 하지 않음",
                    },
                    model: {
                      value: _vm.value.skipIfNotFound,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "skipIfNotFound", _vm._n($$v))
                      },
                      expression: "value.skipIfNotFound",
                    },
                  }),
                  _c(VCheckbox, {
                    attrs: { label: "Queuing Enabled" },
                    model: {
                      value: _vm.value.queuingEnabled,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "queuingEnabled", _vm._n($$v))
                      },
                      expression: "value.queuingEnabled",
                    },
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.value._type == "org.uengine.kernel.bpmn.TimerIntermediateEvent"
            ? _c(
                "div",
                [
                  _c(VSelect, {
                    attrs: {
                      items: _vm.scheduleTypeList,
                      "item-text": "text",
                      "item-value": "value",
                      "item-disabled": "disabled",
                    },
                    model: {
                      value: _vm.value.scheduleType,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "scheduleType", $$v)
                      },
                      expression: "value.scheduleType",
                    },
                  }),
                  _vm.value.scheduleType == "cron"
                    ? _c(
                        VTextField,
                        {
                          attrs: { label: "Quartz Cron Expression" },
                          model: {
                            value: _vm.value.expression,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "expression", $$v)
                            },
                            expression: "value.expression",
                          },
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "http://www.cronmaker.com/",
                                target: "_blank",
                              },
                            },
                            [_vm._v("Cron Maker")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.value.scheduleType == "sec"
                    ? _c(VTextField, {
                        attrs: { label: "Interval in Second", type: "number" },
                        model: {
                          value: _vm.value.expression,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "expression", $$v)
                          },
                          expression: "value.expression",
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.value._type != "org.uengine.kernel.bpmn.CatchingErrorEvent" &&
          _vm.value._type != "org.uengine.kernel.bpmn.ConditionalCatchEvent"
            ? _c(VTextField, {
                attrs: { label: "retryDelay", type: "number" },
                model: {
                  value: _vm.value.retryDelay,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "retryDelay", $$v)
                  },
                  expression: "value.retryDelay",
                },
              })
            : _vm._e(),
          _vm.value._type == "org.uengine.kernel.bpmn.CatchingErrorEvent" ||
          _vm.value._type == "org.uengine.kernel.bpmn.TimerIntermediateEvent"
            ? _c(VTextField, {
                attrs: { label: "부착 액티비티 ID" },
                model: {
                  value: _vm.value.attachedToRef,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "attachedToRef", $$v)
                  },
                  expression: "value.attachedToRef",
                },
              })
            : _vm._e(),
          _vm.value._type ==
            "org.uengine.kernel.bpmn.EscalationIntermediateCatchEvent" ||
          _vm.value._type ==
            "org.uengine.kernel.bpmn.SignalIntermediateCatchEvent"
            ? _c(VTextField, {
                attrs: { label: "Attach Activity ID" },
                model: {
                  value: _vm.value.attachedToRef,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "attachedToRef", $$v)
                  },
                  expression: "value.attachedToRef",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }