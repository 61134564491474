var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.clone.intermediate
        ? _c(
            "sub-controller",
            {
              attrs: { cloneable: "", image: "event_intermediate.png" },
              on: { clone: _vm.intermediateClone },
            },
            [_c("circle-element", { attrs: { width: 100, height: 28 } })],
            1
          )
        : _vm._e(),
      _vm.clone.end
        ? _c(
            "sub-controller",
            {
              attrs: { cloneable: "", image: "event_end.png" },
              on: { clone: _vm.endClone },
            },
            [_c("circle-element", { attrs: { width: 30, height: 30 } })],
            1
          )
        : _vm._e(),
      _vm.clone.gateway
        ? _c(
            "sub-controller",
            {
              attrs: { cloneable: "", image: "gateway_exclusive.png" },
              on: { clone: _vm.exclusiveGatewayClone },
            },
            [_c("circle-element", { attrs: { width: 40, height: 40 } })],
            1
          )
        : _vm._e(),
      _vm.clone.task
        ? _c(
            "sub-controller",
            {
              attrs: { cloneable: "", image: "task.png" },
              on: { clone: _vm.taskClone },
            },
            [_c("circle-element", { attrs: { width: 100, height: 70 } })],
            1
          )
        : _vm._e(),
      _vm.clone.annotaion
        ? _c(
            "sub-controller",
            {
              attrs: { cloneable: "", image: "annotation.png" },
              on: { clone: _vm.annotaionClone },
            },
            [_c("circle-element", { attrs: { width: 100, height: 30 } })],
            1
          )
        : _vm._e(),
      _vm.clone.call
        ? _c(
            "sub-controller",
            { attrs: { image: "popup.png" }, on: { click: _vm.popDefinition } },
            [_c("circle-element", { attrs: { width: 100, height: 30 } })],
            1
          )
        : _vm._e(),
      _vm.clone.wrench
        ? _c("sub-controller", {
            attrs: { image: "wrench.png" },
            on: { click: _vm.showComponentChange },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }