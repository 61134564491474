var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("edge-element", {
        attrs: {
          selectable: "",
          connectable: "",
          deletable: "",
          id: _vm.value.relationView.id,
          vertices: _vm.vertices,
          from: _vm.value.sourceRef,
          to: _vm.value.targetRef,
          _style: {
            "edge-type": "straight",
          },
        },
        on: {
          "update:vertices": function ($event) {
            _vm.vertices = $event
          },
          "update:from": function ($event) {
            return _vm.$set(_vm.value, "sourceRef", $event)
          },
          "update:to": function ($event) {
            return _vm.$set(_vm.value, "targetRef", $event)
          },
          removeShape: function ($event) {
            return _vm.deleteElement(_vm.value)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }