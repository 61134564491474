import { render, staticRenderFns } from "./Task.vue?vue&type=template&id=3fab5f82&scoped=true"
import script from "./Task.vue?vue&type=script&lang=js"
export * from "./Task.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fab5f82",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/kibumpark/Desktop/uEngine-project/platform/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3fab5f82')) {
      api.createRecord('3fab5f82', component.options)
    } else {
      api.reload('3fab5f82', component.options)
    }
    module.hot.accept("./Task.vue?vue&type=template&id=3fab5f82&scoped=true", function () {
      api.rerender('3fab5f82', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/designer/bpmnModeling/bpmn/task/Task.vue"
export default component.exports