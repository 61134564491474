import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c(
        "div",
        [
          _c(
            VRow,
            [
              _c(
                VCol,
                { attrs: { cols: "2" } },
                [
                  _c(VSelect, {
                    attrs: {
                      items: _vm.typeList,
                      "item-text": "key",
                      "item-value": "value",
                    },
                    model: {
                      value: _vm.value._type,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "_type", $$v)
                      },
                      expression: "value._type",
                    },
                  }),
                  _vm._v("\n                    (\n                "),
                ],
                1
              ),
              _c(VCol),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "ul",
        [
          _vm._l(_vm.value.conditionsVt, function (childCondition, index) {
            return _c("table", { key: index, attrs: { width: "100%" } }, [
              _c(
                "td",
                { attrs: { width: "90%" } },
                [
                  _c(childCondition._type.split(".").join("-"), {
                    tag: "component",
                    attrs: { definition: _vm.definition },
                    model: {
                      value: _vm.value.conditionsVt[index],
                      callback: function ($$v) {
                        _vm.$set(_vm.value.conditionsVt, index, $$v)
                      },
                      expression: "value.conditionsVt[index]",
                    },
                  }),
                ],
                1
              ),
              _c(
                "td",
                { attrs: { width: "10%" } },
                [
                  _c(
                    VBtn,
                    {
                      on: {
                        click: function ($event) {
                          return _vm.remove(index)
                        },
                      },
                    },
                    [_c(VIcon, [_vm._v("clear")])],
                    1
                  ),
                ],
                1
              ),
            ])
          }),
          _c(
            VRow,
            [
              _c(
                VCol,
                { attrs: { cols: "3" } },
                [
                  _c(VSelect, {
                    attrs: {
                      items: _vm.conditionTypeList,
                      "item-text": "key",
                      "item-value": "value",
                    },
                    model: {
                      value: _vm.conditionType,
                      callback: function ($$v) {
                        _vm.conditionType = $$v
                      },
                      expression: "conditionType",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "2" } },
                [
                  _c(
                    VBtn,
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.addCondition },
                    },
                    [_vm._v("Add")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("div", { staticStyle: { clear: "both" } }, [
        _vm._v("\n          )\n        "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }