import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { width: "100%" } }, [
    _c(
      "div",
      { staticStyle: { "margin-right": "20px" } },
      [
        _c(VSelect, {
          attrs: {
            label: "Process Data",
            name: "input",
            id: "input",
            items: _vm.processVariableDescriptors,
            "item-text": "name",
            "item-value": "name",
          },
          on: { change: _vm.onChanged },
          scopedSlots: _vm._u([
            {
              key: "message",
              fn: function () {
                return [_vm._v("\n                Roles\n            ")]
              },
              proxy: true,
            },
            {
              key: "item",
              fn: function (data) {
                return [
                  _vm._v(
                    "\n                " +
                      _vm._s(data.item.name) +
                      "\n            "
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.value.name,
            callback: function ($$v) {
              _vm.$set(_vm.value, "name", $$v)
            },
            expression: "value.name",
          },
        }),
      ],
      1
    ),
    _vm.keys.length > 0
      ? _c(
          "div",
          [_c(VSelect, { attrs: { label: "Property", items: _vm.keys } })],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }