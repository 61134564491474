import { VBtn } from 'vuetify/lib/components/VBtn';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        VLayout,
        [
          _vm.drawer
            ? _c(
                "opengraph",
                {
                  attrs: { enableContextmenu: false },
                  on: {
                    connectShape: _vm.onConnectShape,
                    canvasReady: _vm.bindEvents,
                  },
                },
                [
                  _vm._l(_vm.left, function (item, index) {
                    return _c(
                      "div",
                      { key: item.name },
                      [
                        _c("text-element", {
                          attrs: {
                            selectable: "",
                            text: item.name,
                            _style: {
                              "font-color": "grey",
                              "text-anchor": "start",
                            },
                            width: 50,
                            x: 50,
                            y: 100 + index * 20,
                          },
                          on: {
                            selectShape: function ($event) {
                              return _vm.selectedVariable(item)
                            },
                            deSelectShape: _vm.deSelectedVariable,
                          },
                        }),
                        _c("circle-element", {
                          attrs: {
                            x: 100,
                            y: 100 + index * 20,
                            width: 7,
                            height: 7,
                            selectable: "",
                            connectable: "",
                            draggable: "",
                            movable: "",
                          },
                          on: {
                            selectShape: function ($event) {
                              return _vm.selectedVariable(item)
                            },
                            deSelectShape: _vm.deSelectedVariable,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  _vm._l(_vm.mapperRelations, function (relation, idx) {
                    return _c(
                      "div",
                      { key: idx },
                      [
                        _c("edge-element", {
                          attrs: {
                            selectable: "",
                            connectable: "",
                            movable: "",
                            deletable: "",
                            vertices: relation.vertices,
                            _style: {
                              "edge-type": "straight",
                            },
                          },
                          on: {
                            "update:vertices": function ($event) {
                              return _vm.$set(relation, "vertices", $event)
                            },
                            removeShape: function ($event) {
                              return _vm.deleteElement(relation)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  _vm._l(_vm.transformers, function (transformer) {
                    return _c(
                      "rectangle-element",
                      {
                        key: transformer.type,
                        attrs: {
                          x: transformer.location.x,
                          y: transformer.location.y,
                          width: 100,
                          height: 20,
                          text: transformer.name,
                          selectable: "",
                          connectable: "",
                          draggable: "",
                          movable: "",
                          resizable: "",
                          deletable: "",
                        },
                        on: {
                          "update:x": function ($event) {
                            return _vm.$set(transformer.location, "x", $event)
                          },
                          "update:y": function ($event) {
                            return _vm.$set(transformer.location, "y", $event)
                          },
                          selectShape: function ($event) {
                            return _vm.selectedTransformer(transformer)
                          },
                          deSelectShape: function ($event) {
                            return _vm.deSelectedTransformer()
                          },
                          removeShape: function ($event) {
                            return _vm.deleteElement(transformer)
                          },
                        },
                      },
                      [
                        _c(
                          "sub-elements",
                          [
                            _c("text-element", {
                              attrs: {
                                text: transformer.name,
                                "sub-width": "100%",
                                "sub-height": "30px",
                                "sub-align": "left",
                                "sub-vertical-align": "middle",
                                "sub-style": {
                                  "font-color": "grey",
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _vm._l(_vm.right, function (item, index) {
                    return _c(
                      "div",
                      { key: item.name + index },
                      [
                        _c("text-element", {
                          attrs: {
                            selectable: "",
                            text: item.name,
                            _style: {
                              "font-color": "grey",
                              "text-anchor": "end",
                            },
                            width: 50,
                            x: 550,
                            y: 100 + index * 20,
                          },
                          on: {
                            selectShape: function ($event) {
                              return _vm.selectedVariable(item)
                            },
                            deSelectShape: _vm.deSelectedVariable,
                          },
                        }),
                        _c("circle-element", {
                          attrs: {
                            selectable: "",
                            connectable: "",
                            draggable: "",
                            movable: "",
                            x: 500,
                            y: 100 + index * 20,
                            width: 7,
                            height: 7,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
          _c("div", { staticStyle: { height: "300px" } }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "ma-6", staticStyle: { "padding-bottom": "30px" } },
        [
          _c(
            VBtn,
            {
              attrs: { color: "primary", outlined: "" },
              on: { click: _vm.addTransformer },
            },
            [_vm._v("ADD TRANSFORMER")]
          ),
          _vm.editingTransformer
            ? _c(
                "div",
                { staticStyle: { "margin-top": "10px" } },
                [
                  _c(VSelect, {
                    attrs: {
                      label: "트랜스포머 유형",
                      items: _vm.transformerList,
                      "item-text": "type",
                      "item-value": "value",
                      "item-disabled": "disabled",
                    },
                    on: { change: _vm.changeName },
                    model: {
                      value: _vm.editingTransformer._type,
                      callback: function ($$v) {
                        _vm.$set(_vm.editingTransformer, "_type", $$v)
                      },
                      expression: "editingTransformer._type",
                    },
                  }),
                  _c(VTextField, {
                    attrs: { label: "이름" },
                    model: {
                      value: _vm.editingTransformer.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.editingTransformer, "name", $$v)
                      },
                      expression: "editingTransformer.name",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.editingVariable
            ? _c(
                "div",
                { staticStyle: { "margin-top": "10px" } },
                [
                  _c(VTextField, {
                    attrs: { label: "Name" },
                    model: {
                      value: _vm.editingVariable.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.editingVariable, "name", $$v)
                      },
                      expression: "editingVariable.name",
                    },
                  }),
                  _c(VTextField, {
                    attrs: { label: "Display Name" },
                    model: {
                      value: _vm.editingVariable.displayName.text,
                      callback: function ($$v) {
                        _vm.$set(_vm.editingVariable.displayName, "text", $$v)
                      },
                      expression: "editingVariable.displayName.text",
                    },
                  }),
                  _c(VTextField, {
                    attrs: { label: "Default Value In String" },
                    model: {
                      value: _vm.editingVariable.defaultValueInString,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.editingVariable,
                          "defaultValueInString",
                          $$v
                        )
                      },
                      expression: "editingVariable.defaultValueInString",
                    },
                  }),
                  _c(VSwitch, {
                    attrs: { label: "Global" },
                    model: {
                      value: _vm.value.global,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "global", $$v)
                      },
                      expression: "value.global",
                    },
                  }),
                  _c(VSelect, {
                    attrs: { label: "Persist Option" },
                    model: {
                      value: _vm.value.persistOption,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "persistOption", $$v)
                      },
                      expression: "value.persistOption",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }