import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "bpmn-common-panel",
    {
      attrs: {
        image: _vm.image,
        "is-read-only": _vm.isReadOnly,
        "width-style": _vm.widthStyle,
      },
      on: { close: _vm.closePanel },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "template",
        { slot: "edit-property" },
        [
          _vm.value._type == "org.uengine.kernel.bpmn.CallActivity"
            ? _c(
                "div",
                [
                  _c(VAutocomplete, {
                    attrs: {
                      items: _vm.recommendedDefinitionList,
                      filter: _vm.fetchDefinitionList,
                      label: "연결 프로세스 정의",
                    },
                    model: {
                      value: _vm.value.definitionId,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "definitionId", $$v)
                      },
                      expression: "value.definitionId",
                    },
                  }),
                  _vm.value.definitionId
                    ? _c(
                        "div",
                        [
                          _c("label", [_vm._v("연결 변수 매핑")]),
                          _c("bpmn-parameter-contexts", {
                            attrs: {
                              "parameter-contexts": _vm.value.variableBindings,
                              value: _vm.definition,
                              "callee-definition-id": _vm.value.definitionId,
                              "for-sub-process": true,
                              "for-call-activity": true,
                            },
                          }),
                          _c("label", [_vm._v("연결 역할 매핑")]),
                          _c("bpmn-role-parameter-contexts", {
                            attrs: {
                              "parameter-contexts": _vm.value.roleBindings,
                              "callee-definition-id": _vm.value.definitionId,
                              definition: _vm.definition,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.value._type == "org.uengine.kernel.ScriptActivity"
            ? _c(
                "div",
                [
                  _c(VSelect, {
                    attrs: {
                      items: _vm.langList,
                      "item-value": "langList.index",
                      label: "실행 언어",
                    },
                    model: {
                      value: _vm.value.language,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "language", $$v)
                      },
                      expression: "value.language",
                    },
                  }),
                  _c(VTextarea, {
                    attrs: { label: "스크립트", dense: "" },
                    model: {
                      value: _vm.value.script,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "script", $$v)
                      },
                      expression: "value.script",
                    },
                  }),
                  _c(VTextarea, {
                    attrs: { label: "실행 결과 변수 매핑", dense: "" },
                    model: {
                      value: _vm.value.out.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.value.out, "name", $$v)
                      },
                      expression: "value.out.name",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.value._type == "org.uengine.kernel.bpmn.SendTask"
            ? _c(
                "div",
                [
                  _c(VTextField, {
                    attrs: { label: "입력 데이터 (JSON/XML)" },
                    model: {
                      value: _vm.value.inputPayloadTemplate,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "inputPayloadTemplate", $$v)
                      },
                      expression: "value.inputPayloadTemplate",
                    },
                  }),
                  _c(VTextField, {
                    attrs: { label: "결과 입력 변수" },
                    model: {
                      value: _vm.value.dataInput.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.value.dataInput, "name", $$v)
                      },
                      expression: "value.dataInput.name",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.value._type == "org.uengine.kernel.HumanActivity"
            ? _c(
                "div",
                [
                  _c(VTextField, {
                    attrs: { label: "역할", readonly: "" },
                    model: {
                      value: _vm.value.role.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.value.role, "name", $$v)
                      },
                      expression: "value.role.name",
                    },
                  }),
                  _c("label", [_vm._v("파라미터")]),
                  _c("bpmn-parameter-contexts", {
                    attrs: {
                      "parameter-contexts": _vm.value.parameters,
                      value: _vm.value,
                      multi: true,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.value._type == "org.uengine.kernel.bpmn.ServiceTask"
            ? _c(
                "div",
                [
                  _c(VTextField, {
                    attrs: {
                      label: "역할 (추후 레인에서 자동설정)",
                      readonly: "",
                    },
                    model: {
                      value: _vm.value.role.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.value.role, "name", $$v)
                      },
                      expression: "value.role.name",
                    },
                  }),
                  _c(VTextField, {
                    attrs: { label: "호출 URI 패턴" },
                    model: {
                      value: _vm.value.uriTemplate,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "uriTemplate", $$v)
                      },
                      expression: "value.uriTemplate",
                    },
                  }),
                  _vm.value.uriTemplate &&
                  _vm.value.uriTemplate.indexOf("https://") == 0
                    ? _c(VCheckbox, {
                        attrs: { label: "Don't validate the certificate" },
                        model: {
                          value: _vm.value.noValidationForSSL,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "noValidationForSSL", $$v)
                          },
                          expression: "value.noValidationForSSL",
                        },
                      })
                    : _vm._e(),
                  _vm.links
                    ? _c(VSelect, {
                        attrs: {
                          items: _vm.links,
                          "item-text": "link.link",
                          "item-value": "link.href",
                          label: "호출 서비스 선택",
                        },
                        on: { change: _vm.giveJSONHint },
                        model: {
                          value: _vm.value.uriTemplate,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "uriTemplate", $$v)
                          },
                          expression: "value.uriTemplate",
                        },
                      })
                    : _vm._e(),
                  _c(VSelect, {
                    attrs: { items: _vm.methodList, label: "호출 메서드" },
                    model: {
                      value: _vm.value.method,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "method", $$v)
                      },
                      expression: "value.method",
                    },
                  }),
                  "GET,DELETE".indexOf(_vm.value.method) == -1
                    ? _c(VTextarea, {
                        attrs: {
                          label: "입력 데이터 (JSON template)",
                          dense: "",
                        },
                        model: {
                          value: _vm.value.inputPayloadTemplate,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "inputPayloadTemplate", $$v)
                          },
                          expression: "value.inputPayloadTemplate",
                        },
                      })
                    : _vm._e(),
                  _c("label", [_vm._v("결과 데이터 매핑")]),
                  _c("bpmn-parameter-contexts", {
                    attrs: {
                      "parameter-contexts": _vm.value.outputMapping,
                      value: _vm.definition,
                    },
                  }),
                  _c(VCheckbox, {
                    attrs: {
                      label: "리소스 없을 경우 (404) 오류 처리 하지 않음",
                    },
                    model: {
                      value: _vm.value.skipIfNotFound,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "skipIfNotFound", $$v)
                      },
                      expression: "value.skipIfNotFound",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.value._type == "org.uengine.kernel.bpmn.ReceiveTask"
            ? _c(
                "div",
                [
                  _c(VTextField, {
                    attrs: {
                      label: "역할 (추후 레인에서 자동설정)",
                      readonly: "",
                    },
                    model: {
                      value: _vm.value.role.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.value.role, "name", $$v)
                      },
                      expression: "value.role.name",
                    },
                  }),
                  _c(VTextField, {
                    attrs: { label: "호출 URI 패턴" },
                    model: {
                      value: _vm.value.uriTemplate,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "uriTemplate", $$v)
                      },
                      expression: "value.uriTemplate",
                    },
                  }),
                  _vm.value.uriTemplate &&
                  _vm.value.uriTemplate.indexOf("https://") == 0
                    ? _c(VCheckbox, {
                        attrs: { label: "Don't validate the certificate" },
                        model: {
                          value: _vm.value.noValidationForSSL,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "noValidationForSSL", $$v)
                          },
                          expression: "value.noValidationForSSL",
                        },
                      })
                    : _vm._e(),
                  _vm.links
                    ? _c(VSelect, {
                        attrs: {
                          items: _vm.links,
                          "item-text": "link.link",
                          "item-value": "link.href",
                          label: "호출 서비스 선택",
                        },
                        on: { change: _vm.giveJSONHint },
                        model: {
                          value: _vm.value.uriTemplate,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "uriTemplate", $$v)
                          },
                          expression: "value.uriTemplate",
                        },
                      })
                    : _vm._e(),
                  _c(VSelect, {
                    attrs: { items: _vm.methodList, label: "호출 메서드" },
                    model: {
                      value: _vm.value.method,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "method", $$v)
                      },
                      expression: "value.method",
                    },
                  }),
                  "GET,DELETE".indexOf(_vm.value.method) == -1
                    ? _c(VTextarea, {
                        attrs: {
                          label: "입력 데이터 (JSON template)",
                          dense: "",
                        },
                        model: {
                          value: _vm.value.inputPayloadTemplate,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "inputPayloadTemplate", $$v)
                          },
                          expression: "value.inputPayloadTemplate",
                        },
                      })
                    : _vm._e(),
                  _c("label", [_vm._v("결과 데이터 매핑")]),
                  _c("bpmn-parameter-contexts", {
                    attrs: {
                      "parameter-contexts": _vm.value.outputMapping,
                      value: _vm.definition,
                    },
                  }),
                  _c(VCheckbox, {
                    attrs: {
                      label: "리소스 없을 경우 (404) 오류 처리 하지 않음",
                    },
                    model: {
                      value: _vm.value.skipIfNotFound,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "skipIfNotFound", $$v)
                      },
                      expression: "value.skipIfNotFound",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.value._type != "org.uengine.kernel.ScriptActivity"
            ? _c(VTextField, {
                attrs: { label: "retryDelay", type: "number" },
                model: {
                  value: _vm.value.retryDelay,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "retryDelay", $$v)
                  },
                  expression: "value.retryDelay",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }