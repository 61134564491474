import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSpeedDial } from 'vuetify/lib/components/VSpeedDial';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "canvas-panel" },
    [
      _c(
        VLayout,
        { attrs: { right: "" } },
        [
          _vm.value
            ? _c(
                "opengraph",
                {
                  ref: "opengraph",
                  attrs: {
                    "focus-canvas-on-select": "",
                    wheelScalable: "",
                    dragPageMovable: _vm.dragPageMovable,
                    enableContextmenu: false,
                    enableRootContextmenu: false,
                    enableHotkeyCtrlC: false,
                    enableHotkeyCtrlV: false,
                    enableHotkeyDelete: false,
                    sliderLocationScale: _vm.sliderLocationScale,
                    slider: true,
                    movable: !_vm.monitor,
                    resizable: !_vm.monitor,
                    selectable: !_vm.monitor,
                    connectable: !_vm.monitor,
                    width: 100000,
                    imageBase: _vm.imageBase,
                    height: 100000,
                  },
                  on: {
                    canvasReady: _vm.bindEvents,
                    connectShape: _vm.onConnectShape,
                    divideLane: _vm.onDivideLane,
                  },
                },
                [
                  _vm._l(Object.keys(_vm.value.elements), function (roleId) {
                    return _c(
                      "div",
                      { key: "role" + roleId },
                      [
                        roleId &&
                        _vm.value.elements[roleId] &&
                        _vm.value.elements[roleId]._type ==
                          "org.uengine.kernel.Role"
                          ? _c("bpmn-role", {
                              ref: roleId,
                              refInFor: true,
                              attrs: { value: _vm.value.elements[roleId] },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(
                                    _vm.value.elements,
                                    roleId,
                                    $event
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  _vm._l(Object.keys(_vm.value.elements), function (elementId) {
                    return _c(
                      "div",
                      { key: elementId },
                      [
                        elementId && _vm.value.elements[elementId] != null
                          ? _c(
                              _vm.getComponentByClassName(
                                _vm.value.elements[elementId]._type
                              ),
                              {
                                ref: elementId,
                                refInFor: true,
                                tag: "component",
                                attrs: {
                                  value: _vm.value.elements[elementId],
                                  definition: _vm.value,
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.value.elements,
                                      elementId,
                                      $event
                                    )
                                  },
                                },
                              }
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  _vm._l(
                    Object.keys(_vm.value.relations),
                    function (relationId) {
                      return _c(
                        "div",
                        { key: relationId },
                        [
                          relationId &&
                          _vm.value.relations[relationId] != null &&
                          _vm.value.relations[relationId]._type ==
                            "org.uengine.kernel.bpmn.MessageFlow"
                            ? _c("bpmn-message-flow", {
                                ref: relationId,
                                refInFor: true,
                                attrs: {
                                  value: _vm.value.relations[relationId],
                                  definition: _vm.value,
                                },
                              })
                            : relationId &&
                              _vm.value.relations[relationId] != null
                            ? _c("bpmn-relation", {
                                ref: relationId,
                                refInFor: true,
                                attrs: {
                                  value: _vm.value.relations[relationId],
                                  definition: _vm.value,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    }
                  ),
                ],
                2
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "bpmn-mobile-btn",
              staticStyle: {
                position: "fixed",
                bottom: "20px",
                right: "20px",
                "z-index": "999",
              },
            },
            [
              _c(
                VSpeedDial,
                {
                  attrs: { direction: "top", transition: "slide-y-transition" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function () {
                        return [
                          _c(
                            VBtn,
                            {
                              attrs: {
                                color: "blue darken-2",
                                dark: "",
                                fab: "",
                                small: "",
                              },
                              model: {
                                value: _vm.fab,
                                callback: function ($$v) {
                                  _vm.fab = $$v
                                },
                                expression: "fab",
                              },
                            },
                            [
                              _vm.fab
                                ? _c(VIcon, [
                                    _vm._v(
                                      "\n                            mdi-playlist-remove\n                        "
                                    ),
                                  ])
                                : _c(VIcon, [
                                    _vm._v(
                                      "\n                            mdi-playlist-check\n                        "
                                    ),
                                  ]),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.fab,
                    callback: function ($$v) {
                      _vm.fab = $$v
                    },
                    expression: "fab",
                  },
                },
                [
                  _c(VMenu, {
                    attrs: { "offset-y": "", "open-on-hover": "", left: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              VBtn,
                              _vm._g(
                                {
                                  staticStyle: {
                                    "margin-right": "15px",
                                    "margin-top": "15px",
                                  },
                                  attrs: {
                                    text: "",
                                    color: "primary",
                                    dark: "",
                                  },
                                  on: { click: _vm.openDefinitionSettings },
                                },
                                on
                              ),
                              [
                                _c(VIcon, [_vm._v("settings")]),
                                _c("div", [_vm._v("Settings")]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c(VMenu, {
                    attrs: { "offset-y": "", "open-on-hover": "", left: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              VBtn,
                              _vm._g(
                                {
                                  staticStyle: {
                                    "margin-right": "15px",
                                    "margin-top": "15px",
                                  },
                                  attrs: {
                                    text: "",
                                    color: "orange",
                                    dark: "",
                                  },
                                  on: { click: _vm.openProcessVariables },
                                },
                                on
                              ),
                              [
                                _c(VIcon, [_vm._v("sort_by_alpha")]),
                                _c("div", [_vm._v("Vars")]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c(
                    VMenu,
                    {
                      staticStyle: {
                        "z-index": "999 !important",
                        position: "relative",
                      },
                      attrs: { "offset-y": "", "open-on-hover": "", left: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _vm.isReadOnlyModel
                                ? _c(
                                    VBtn,
                                    {
                                      staticStyle: {
                                        "margin-right": "5px",
                                        "margin-top": "15px",
                                      },
                                      attrs: { color: "primary", text: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.saveComposition("fork")
                                        },
                                      },
                                    },
                                    [
                                      _c(VIcon, [
                                        _vm._v(_vm._s(_vm.icon.fork)),
                                      ]),
                                      _c("div", [_vm._v("FORK")]),
                                    ],
                                    1
                                  )
                                : _c(
                                    VBtn,
                                    _vm._g(
                                      {
                                        staticStyle: {
                                          "margin-right": "5px",
                                          "margin-top": "15px",
                                        },
                                        attrs: { color: "primary", text: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.saveComposition("save")
                                          },
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _c(VIcon, [
                                        _vm._v(_vm._s(_vm.icon.save)),
                                      ]),
                                      _c("div", [_vm._v("SAVE")]),
                                    ],
                                    1
                                  ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      !_vm.isClazzModeling
                        ? _c(
                            VList,
                            _vm._l(_vm.saveItems, function (item) {
                              return _c(
                                VListItem,
                                {
                                  key: item.title,
                                  on: {
                                    click: function ($event) {
                                      return _vm.functionSelect(item.title)
                                    },
                                  },
                                },
                                [
                                  _c(VListItemTitle, [
                                    _vm._v(_vm._s(item.title)),
                                  ]),
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.isOwnModel && _vm.isServerModel && !_vm.isReadOnlyModel
                    ? _c(
                        "div",
                        [
                          _c(
                            VBtn,
                            {
                              staticStyle: {
                                "margin-right": "15px",
                                "margin-top": "15px",
                              },
                              attrs: {
                                text: "",
                                color: "primary",
                                disabled: !_vm.initLoad,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.openInviteUsers()
                                },
                              },
                            },
                            [
                              _c(VIcon, [_vm._v(_vm._s(_vm.icon.share))]),
                              _c("div", { staticClass: "bpmn-btn-text" }, [
                                _vm._v("SHARE"),
                              ]),
                              _vm.requestCount
                                ? _c(
                                    VAvatar,
                                    {
                                      staticStyle: { "margin-left": "2px" },
                                      attrs: { size: "25", color: "red" },
                                    },
                                    [_vm._v(_vm._s(_vm.requestCount))]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.versions
                    ? _c(
                        "div",
                        { staticStyle: { "margin-right": "10px" } },
                        _vm._l(
                          _vm.versions.slice().reverse(),
                          function (version) {
                            return _c(
                              VSelect,
                              _vm._g(
                                {
                                  key: version,
                                  staticStyle: {
                                    "margin-top": "10px",
                                    "margin-right": "15px",
                                    "max-width": "150px",
                                  },
                                  attrs: { value: version },
                                  on: { change: _vm.changeVersion },
                                  model: {
                                    value: _vm.selectedVersion,
                                    callback: function ($$v) {
                                      _vm.selectedVersion = $$v
                                    },
                                    expression: "selectedVersion",
                                  },
                                },
                                _vm.on
                              ),
                              [
                                _vm.productionVersionId == version.versionId
                                  ? _c(VChip, [_vm._v("production")])
                                  : _vm._e(),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      )
                    : _vm._e(),
                  _vm.value
                    ? _c(
                        "div",
                        { staticStyle: { "margin-right": "10px" } },
                        [
                          _c(VSelect, {
                            staticClass: "bpmn-language-select",
                            staticStyle: {
                              "margin-top": "10px",
                              "margin-right": "15px",
                              "max-width": "150px",
                            },
                            attrs: {
                              items: _vm.languageItems,
                              "item-value": "value",
                              "item-text": "title",
                              label: "Language",
                            },
                            on: { change: _vm.changeLocale },
                            model: {
                              value: _vm.selectedLocale,
                              callback: function ($$v) {
                                _vm.selectedLocale = $$v
                              },
                              expression: "selectedLocale",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    [
                      _c(VMenu, {
                        attrs: {
                          "offset-y": "",
                          "open-on-hover": "",
                          left: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  VBtn,
                                  _vm._g(
                                    {
                                      staticStyle: {
                                        "margin-right": "15px",
                                        "margin-top": "15px",
                                      },
                                      attrs: {
                                        color: "primary",
                                        dark: "",
                                        fab: "",
                                        small: "",
                                      },
                                      on: { click: _vm.initiateProcess },
                                    },
                                    on
                                  ),
                                  [_c(VIcon, [_vm._v("play_arrow")])],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c(VMenu, {
                        attrs: {
                          "offset-y": "",
                          "open-on-hover": "",
                          left: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  VBtn,
                                  _vm._g(
                                    {
                                      staticStyle: {
                                        "margin-right": "15px",
                                        "margin-top": "15px",
                                      },
                                      attrs: {
                                        color: "orange",
                                        dark: "",
                                        fab: "",
                                        small: "",
                                      },
                                      on: { click: _vm.generatedefinition },
                                    },
                                    on
                                  ),
                                  [
                                    _vm.id && _vm.id.indexOf("@") == -1
                                      ? _c(VIcon, [_vm._v("list")])
                                      : _c(VIcon, [_vm._v("history")]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VFlex,
            { staticStyle: { justify: "end", align: "start" } },
            [
              !_vm.monitor
                ? _c(
                    VRow,
                    {
                      staticClass: "gs-model-z-index-1",
                      staticStyle: {
                        position: "absolute",
                        left: "50%",
                        transform: "translate(-50%, 0%)",
                        "margin-top": "0px",
                      },
                      attrs: { justify: "end", align: "start" },
                    },
                    [
                      _c(VTextField, {
                        staticStyle: {
                          "margin-right": "10px",
                          "margin-top": "8px",
                          "max-width": "120px",
                        },
                        attrs: { label: "Process Name" },
                        model: {
                          value: _vm.projectName,
                          callback: function ($$v) {
                            _vm.projectName = $$v
                          },
                          expression: "projectName",
                        },
                      }),
                      _c(VRow, { staticClass: "bpmn-web-btn" }, [
                        _c(
                          "div",
                          [
                            _c(VMenu, {
                              attrs: {
                                "offset-y": "",
                                "open-on-hover": "",
                                left: "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          VBtn,
                                          _vm._g(
                                            {
                                              staticClass: "bpmn-btn",
                                              staticStyle: {
                                                "margin-right": "15px",
                                                "margin-top": "15px",
                                              },
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                                dark: "",
                                              },
                                              on: {
                                                click:
                                                  _vm.openDefinitionSettings,
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _c(VIcon, [_vm._v("settings")]),
                                            _c(
                                              "div",
                                              { staticClass: "bpmn-btn-text" },
                                              [_vm._v("Settings")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2784467446
                              ),
                            }),
                            _c(VMenu, {
                              attrs: {
                                "offset-y": "",
                                "open-on-hover": "",
                                left: "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          VBtn,
                                          _vm._g(
                                            {
                                              staticClass: "bpmn-btn",
                                              staticStyle: {
                                                "margin-right": "15px",
                                                "margin-top": "15px",
                                              },
                                              attrs: {
                                                text: "",
                                                color: "orange",
                                                dark: "",
                                              },
                                              on: {
                                                click: _vm.openProcessVariables,
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _c(VIcon, [
                                              _vm._v("sort_by_alpha"),
                                            ]),
                                            _c(
                                              "div",
                                              { staticClass: "bpmn-btn-text" },
                                              [_vm._v("Vars")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2030933799
                              ),
                            }),
                            _c(
                              VMenu,
                              {
                                attrs: {
                                  "offset-y": "",
                                  "open-on-hover": "",
                                  left: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _vm.isReadOnlyModel
                                            ? _c(
                                                VBtn,
                                                {
                                                  staticClass: "bpmn-btn",
                                                  staticStyle: {
                                                    "margin-right": "5px",
                                                    "margin-top": "15px",
                                                  },
                                                  attrs: {
                                                    color: "primary",
                                                    text: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.saveComposition(
                                                        "fork"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(VIcon, [
                                                    _vm._v(
                                                      _vm._s(_vm.icon.fork)
                                                    ),
                                                  ]),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "bpmn-btn-text",
                                                    },
                                                    [_vm._v("FORK")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c(
                                                VBtn,
                                                _vm._g(
                                                  {
                                                    staticClass: "bpmn-btn",
                                                    staticStyle: {
                                                      "margin-right": "5px",
                                                      "margin-top": "15px",
                                                    },
                                                    attrs: {
                                                      color: "primary",
                                                      text: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.saveComposition(
                                                          "save"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(VIcon, [
                                                    _vm._v(
                                                      _vm._s(_vm.icon.save)
                                                    ),
                                                  ]),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "bpmn-btn-text",
                                                    },
                                                    [_vm._v("SAVE")]
                                                  ),
                                                ],
                                                1
                                              ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3418337325
                                ),
                              },
                              [
                                !_vm.isClazzModeling
                                  ? _c(
                                      VList,
                                      _vm._l(_vm.saveItems, function (item) {
                                        return _c(
                                          VListItem,
                                          {
                                            key: item.title,
                                            on: {
                                              click: function ($event) {
                                                return _vm.functionSelect(
                                                  item.title
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(VListItemTitle, [
                                              _vm._v(_vm._s(item.title)),
                                            ]),
                                          ],
                                          1
                                        )
                                      }),
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.isOwnModel &&
                        _vm.isServerModel &&
                        !_vm.isReadOnlyModel
                          ? _c(
                              "div",
                              [
                                _c(
                                  VBtn,
                                  {
                                    staticStyle: {
                                      "margin-right": "15px",
                                      "margin-top": "15px",
                                    },
                                    attrs: {
                                      text: "",
                                      color: "primary",
                                      disabled: !_vm.initLoad,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openInviteUsers()
                                      },
                                    },
                                  },
                                  [
                                    _c(VIcon, [
                                      _vm._v(_vm._s(_vm.icon.share)),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "bpmn-btn-text" },
                                      [_vm._v("SHARE")]
                                    ),
                                    _vm.requestCount
                                      ? _c(
                                          VAvatar,
                                          {
                                            staticStyle: {
                                              "margin-left": "2px",
                                            },
                                            attrs: { size: "25", color: "red" },
                                          },
                                          [_vm._v(_vm._s(_vm.requestCount))]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isReadOnlyModel
                          ? _c(
                              "div",
                              [
                                _c(
                                  VBtn,
                                  _vm._g(
                                    {
                                      staticClass: "k8s-hide-code-btn",
                                      staticStyle: {
                                        "margin-right": "5px",
                                        "margin-top": "15px",
                                      },
                                      attrs: {
                                        color: _vm.joinRequestedText.show
                                          ? "primary"
                                          : "success",
                                        text: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.requestInviteUser()
                                        },
                                      },
                                    },
                                    _vm.on
                                  ),
                                  [
                                    _vm.joinRequestedText.show
                                      ? _c(
                                          "div",
                                          [
                                            _c(VIcon, [
                                              _vm._v(_vm._s(_vm.icon.join)),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(_vm.joinRequestedText.text) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.versions
                          ? _c(
                              "div",
                              { staticStyle: { "margin-right": "10px" } },
                              _vm._l(
                                _vm.versions.slice().reverse(),
                                function (version) {
                                  return _c(
                                    VSelect,
                                    _vm._g(
                                      {
                                        key: version,
                                        staticStyle: {
                                          "margin-top": "10px",
                                          "margin-right": "15px",
                                          "max-width": "150px",
                                        },
                                        attrs: { value: version },
                                        on: { change: _vm.changeVersion },
                                        model: {
                                          value: _vm.selectedVersion,
                                          callback: function ($$v) {
                                            _vm.selectedVersion = $$v
                                          },
                                          expression: "selectedVersion",
                                        },
                                      },
                                      _vm.on
                                    ),
                                    [
                                      _vm.productionVersionId ==
                                      version.versionId
                                        ? _c(VChip, [_vm._v("production")])
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                        _vm.value
                          ? _c(
                              "div",
                              { staticStyle: { "margin-right": "10px" } },
                              [
                                _c(VSelect, {
                                  staticClass: "bpmn-language-select",
                                  staticStyle: {
                                    "margin-top": "10px",
                                    "margin-right": "15px",
                                    "max-width": "150px",
                                  },
                                  attrs: {
                                    items: _vm.languageItems,
                                    "item-value": "value",
                                    "item-text": "title",
                                    label: "Language",
                                  },
                                  on: { change: _vm.changeLocale },
                                  model: {
                                    value: _vm.selectedLocale,
                                    callback: function ($$v) {
                                      _vm.selectedLocale = $$v
                                    },
                                    expression: "selectedLocale",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          [
                            _c(VMenu, {
                              attrs: {
                                "offset-y": "",
                                "open-on-hover": "",
                                left: "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          VBtn,
                                          _vm._g(
                                            {
                                              staticStyle: {
                                                "margin-right": "15px",
                                                "margin-top": "15px",
                                              },
                                              attrs: {
                                                color: "primary",
                                                dark: "",
                                                fab: "",
                                                small: "",
                                              },
                                              on: {
                                                click: _vm.initiateProcess,
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _c(VIcon, [
                                              _vm._v("play_arrow"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4251299703
                              ),
                            }),
                            _c(VMenu, {
                              attrs: {
                                "offset-y": "",
                                "open-on-hover": "",
                                left: "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          VBtn,
                                          _vm._g(
                                            {
                                              staticStyle: {
                                                "margin-right": "15px",
                                                "margin-top": "15px",
                                              },
                                              attrs: {
                                                color: "orange",
                                                dark: "",
                                                fab: "",
                                                small: "",
                                              },
                                              on: {
                                                click: _vm.generatedefinition,
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _vm.id && _vm.id.indexOf("@") == -1
                                              ? _c(VIcon, [_vm._v("list")])
                                              : _c(VIcon, [
                                                  _vm._v("history"),
                                                ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2104234864
                              ),
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                : _c(
                    VRow,
                    {
                      staticStyle: { "margin-right": "15px" },
                      attrs: { justify: "end", align: "start" },
                    },
                    [
                      _c(VTextField, {
                        staticStyle: {
                          "margin-right": "10px",
                          "margin-top": "8px",
                          "max-width": "300px",
                        },
                        attrs: {
                          label: "Instance Name",
                          type: "text",
                          readonly: "",
                        },
                        model: {
                          value: _vm.instanceName,
                          callback: function ($$v) {
                            _vm.instanceName = $$v
                          },
                          expression: "instanceName",
                        },
                      }),
                      _c(
                        "div",
                        { staticStyle: { "margin-right": "10px" } },
                        [
                          _c(VMenu, {
                            attrs: {
                              "offset-y": "",
                              "open-on-hover": "",
                              left: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      VBtn,
                                      _vm._g(
                                        {
                                          staticStyle: {
                                            "margin-right": "15px",
                                            "margin-top": "15px",
                                          },
                                          attrs: {
                                            text: "",
                                            id: "instanceVariables",
                                            color: "primary",
                                            dark: "",
                                          },
                                          on: {
                                            click: _vm.openInstanceVariables,
                                          },
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          "\n                                Variables\n                            "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c(VMenu, {
                            attrs: {
                              "offset-y": "",
                              "open-on-hover": "",
                              left: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      VBtn,
                                      _vm._g(
                                        {
                                          staticStyle: {
                                            "margin-right": "15px",
                                            "margin-top": "15px",
                                          },
                                          attrs: {
                                            text: "",
                                            id: "userPicker",
                                            color: "orange",
                                            dark: "",
                                          },
                                          on: { click: _vm.openUserPicker },
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          "\n                                Role Mappings\n                            "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm.versions
                        ? _c(
                            "div",
                            { staticStyle: { "margin-right": "10px" } },
                            _vm._l(
                              _vm.versions.slice().reverse(),
                              function (version) {
                                return _c(
                                  VSelect,
                                  _vm._g(
                                    {
                                      key: version,
                                      staticStyle: {
                                        "margin-top": "10px",
                                        "margin-right": "15px",
                                        "max-width": "150px",
                                      },
                                      attrs: { value: version },
                                      on: { change: _vm.changeVersion },
                                      model: {
                                        value: _vm.selectedVersion,
                                        callback: function ($$v) {
                                          _vm.selectedVersion = $$v
                                        },
                                        expression: "selectedVersion",
                                      },
                                    },
                                    _vm.on
                                  ),
                                  [
                                    _vm.productionVersionId == version.versionId
                                      ? _c(VChip, [_vm._v("production")])
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          )
                        : _vm._e(),
                      _vm.value
                        ? _c("bpmn-instance-variables", {
                            ref: "instanceVariables",
                            attrs: {
                              id: _vm.instanceId,
                              definition: _vm.value,
                            },
                          })
                        : _vm._e(),
                      _vm.value
                        ? _c("user-picker", {
                            ref: "userPicker",
                            staticStyle: { "min-width": "70%" },
                            attrs: { id: _vm.instanceId, roles: _vm.roles },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
            ],
            1
          ),
          !_vm.monitor
            ? _c(
                VCard,
                { staticClass: "tools", staticStyle: { top: "100px" } },
                [
                  _c(
                    VTooltip,
                    {
                      attrs: { right: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "span",
                                  _vm._g(
                                    {
                                      staticClass: "bpmn-icon-hand-tool hands",
                                      style: _vm.handsStyle,
                                      attrs: { _width: "30", _height: "30" },
                                      on: { click: _vm.changeMultiple },
                                    },
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        960570545
                      ),
                    },
                    [_c("span", [_vm._v("Drag On/Off")])]
                  ),
                  _vm._l(_vm.dragItems, function (item, idx) {
                    return _c(
                      VTooltip,
                      {
                        key: idx,
                        attrs: { right: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "span",
                                    _vm._g(
                                      {
                                        staticClass: "icons draggable",
                                        class: item.icon,
                                        attrs: {
                                          align: "center",
                                          _component: item.component,
                                          _width: item.width,
                                          _height: item.height,
                                        },
                                      },
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_c("span", [_vm._v(_vm._s(item.label))])]
                    )
                  }),
                  _c(
                    VTooltip,
                    {
                      attrs: { right: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  VBtn,
                                  _vm._g(
                                    {
                                      attrs: { text: "", small: "", right: "" },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.undo.apply(null, arguments)
                                        },
                                      },
                                    },
                                    on
                                  ),
                                  [
                                    _c(VIcon, { attrs: { medium: "" } }, [
                                      _vm._v("mdi-undo"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3718417264
                      ),
                    },
                    [_c("span", [_vm._v("Undo")])]
                  ),
                  _c(
                    VTooltip,
                    {
                      attrs: { right: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  VBtn,
                                  _vm._g(
                                    {
                                      attrs: { text: "", small: "", right: "" },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.redo.apply(null, arguments)
                                        },
                                      },
                                    },
                                    on
                                  ),
                                  [
                                    _c(VIcon, { attrs: { medium: "" } }, [
                                      _vm._v("mdi-redo"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2942083568
                      ),
                    },
                    [_c("span", [_vm._v("Redo")])]
                  ),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.value
        ? _c("bpmn-component-changer", {
            attrs: { data: _vm.componentChangerData },
          })
        : _vm._e(),
      _vm.value
        ? _c(
            VDialog,
            {
              attrs: { "max-width": "900" },
              model: {
                value: _vm.processVariablesDialog,
                callback: function ($$v) {
                  _vm.processVariablesDialog = $$v
                },
                expression: "processVariablesDialog",
              },
            },
            [
              _c(
                VCard,
                [
                  _c(VCardTitle, [_vm._v("Process Variables")]),
                  _c(
                    VCardText,
                    [
                      _c("bpmn-object-grid", {
                        attrs: { "full-fledged": true },
                        model: {
                          value: _vm.value.processVariableDescriptors,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.value,
                              "processVariableDescriptors",
                              $$v
                            )
                          },
                          expression: "value.processVariableDescriptors",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCardActions,
                    [
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          attrs: { color: "primary", text: "" },
                          on: { click: _vm.closeProcessVariables },
                        },
                        [_vm._v("Close")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.value
        ? _c(
            VDialog,
            {
              ref: "definitionSettings",
              attrs: { "max-width": "320" },
              model: {
                value: _vm.definitionSettings,
                callback: function ($$v) {
                  _vm.definitionSettings = $$v
                },
                expression: "definitionSettings",
              },
            },
            [
              _c(
                VCard,
                [
                  _c(VCardTitle, [_vm._v("Definition Settings")]),
                  _c(
                    VCardText,
                    [
                      _c(
                        VForm,
                        {
                          attrs: { novalidate: "" },
                          on: {
                            submit: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.submit.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            VContainer,
                            [
                              _c(VTextField, {
                                attrs: { label: "Description" },
                                model: {
                                  value:
                                    _vm.defintionSettings.shortDescription.text,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.defintionSettings.shortDescription,
                                      "text",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "defintionSettings.shortDescription.text",
                                },
                              }),
                              _c(VTextField, {
                                attrs: { label: "Instance Name Pattern" },
                                model: {
                                  value: _vm.instanceNamePattern,
                                  callback: function ($$v) {
                                    _vm.instanceNamePattern = $$v
                                  },
                                  expression: "instanceNamePattern",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(VSwitch, {
                            attrs: {
                              id: "my-test1",
                              name: "my-test1",
                              color: "primary",
                              label: "Initiate by event",
                            },
                            model: {
                              value: _vm.initiateByFirstWorkitem,
                              callback: function ($$v) {
                                _vm.initiateByFirstWorkitem = $$v
                              },
                              expression: "initiateByFirstWorkitem",
                            },
                          }),
                          _c(VSwitch, {
                            attrs: {
                              id: "my-test1",
                              name: "my-test1",
                              color: "primary",
                              label: " Volatile",
                            },
                            model: {
                              value: _vm.volatile,
                              callback: function ($$v) {
                                _vm.volatile = $$v
                              },
                              expression: "volatile",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    VCardActions,
                    [
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          attrs: { color: "primary", text: "" },
                          on: { click: _vm.closeDefinitionSettings },
                        },
                        [_vm._v("Close")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._m(0),
      _c("model-storage-dialog", {
        attrs: {
          condition: _vm.storageCondition,
          showDialog: _vm.showStorageDialog,
          isClazzModeling: _vm.isClazzModeling,
        },
        on: {
          updateClassModelingId: _vm.updateClassModelingId,
          save: _vm.saveModel,
          fork: _vm.forkModel,
          backup: _vm.backupModel,
          close: _vm.storageDialogCancel,
        },
      }),
      _c("model-canvas-share-dialog", {
        attrs: {
          showDialog: _vm.inviteDialog,
          checkPublic: _vm.showPublicModel,
          canvas: _vm.canvas,
          canvasComponentName: "bpmn-modeling-canvas",
        },
        on: {
          all: _vm.invitePublic,
          apply: _vm.applyInviteUsers,
          close: _vm.closeInviteUsers,
          add: _vm.addInviteUser,
          remove: _vm.removeInviteUser,
        },
        model: {
          value: _vm.inviteLists,
          callback: function ($$v) {
            _vm.inviteLists = $$v
          },
          expression: "inviteLists",
        },
      }),
      _c("modeler-image-generator", { ref: "modeler-image-generator" }),
      _vm._l(_vm.filteredMouseEventHandlers, function (otherMouseEvent, email) {
        return _c(
          "div",
          { key: email },
          [
            _c("MouseCursorComponent", {
              attrs: { mouseEvent: otherMouseEvent, email: email },
            }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "custom-menu" }, [
      _c("li", { attrs: { "data-action": "backToHere" } }, [
        _vm._v("Back To Here"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }