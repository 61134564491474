var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.data.parameterContexts, function (parameterContext, idx) {
        return _c(
          "md-layout",
          { key: idx },
          [
            _c(
              "md-layout",
              { attrs: { "md-flex": "30" } },
              [
                _c(
                  "md-input-container",
                  [
                    _c("label", [_vm._v("Callee Roles")]),
                    _vm.calleeDefinition.loaded
                      ? _c(
                          "md-select",
                          {
                            attrs: { name: "input", id: "input" },
                            model: {
                              value: parameterContext.argument,
                              callback: function ($$v) {
                                _vm.$set(parameterContext, "argument", $$v)
                              },
                              expression: "parameterContext.argument",
                            },
                          },
                          _vm._l(_vm.calleeDefinition.roles, function (role) {
                            return _c(
                              "md-option",
                              { key: role.name, attrs: { value: role.name } },
                              [
                                _vm._v(
                                  "\n                          " +
                                    _vm._s(role.name) +
                                    "\n                      "
                                ),
                              ]
                            )
                          }),
                          1
                        )
                      : _c("md-input", {
                          model: {
                            value: parameterContext.argument,
                            callback: function ($$v) {
                              _vm.$set(parameterContext, "argument", $$v)
                            },
                            expression: "parameterContext.argument",
                          },
                        }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "md-layout",
              { attrs: { "md-flex": "30" } },
              [
                _c(
                  "md-input-container",
                  [
                    _c("label", [_vm._v("Caller Roles")]),
                    _c(
                      "md-select",
                      {
                        model: {
                          value: parameterContext.role.name,
                          callback: function ($$v) {
                            _vm.$set(parameterContext.role, "name", $$v)
                          },
                          expression: "parameterContext.role.name",
                        },
                      },
                      _vm._l(_vm.definition.roles, function (role) {
                        return _c(
                          "md-option",
                          { key: role.name, attrs: { value: role.name } },
                          [
                            _vm._v(
                              "\n                          " +
                                _vm._s(role.name) +
                                "\n                      "
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "md-layout",
              { attrs: { "md-flex": "30" } },
              [
                _c(
                  "md-input-container",
                  [
                    _c("label", [_vm._v("연결 방향")]),
                    _c(
                      "md-select",
                      {
                        model: {
                          value: parameterContext.direction,
                          callback: function ($$v) {
                            _vm.$set(parameterContext, "direction", $$v)
                          },
                          expression: "parameterContext.direction",
                        },
                      },
                      [
                        _c("md-option", { attrs: { value: "in-out" } }, [
                          _vm._v("IN-OUT"),
                        ]),
                        _c("md-option", { attrs: { value: "in" } }, [
                          _vm._v("IN"),
                        ]),
                        _c("md-option", { attrs: { value: "out" } }, [
                          _vm._v("OUT"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "md-layout",
              { attrs: { "md-flex": "20" } },
              [
                _c(
                  "md-checkbox",
                  {
                    model: {
                      value: parameterContext.multipleInput,
                      callback: function ($$v) {
                        _vm.$set(parameterContext, "multipleInput", $$v)
                      },
                      expression: "parameterContext.multipleInput",
                    },
                  },
                  [_vm._v("Multi")]
                ),
              ],
              1
            ),
            _c(
              "md-layout",
              { attrs: { "md-flex": "20" } },
              [
                _c(
                  "md-icon",
                  {
                    staticClass: "md-primary",
                    staticStyle: { cursor: "pointer" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.remove(parameterContext)
                      },
                    },
                  },
                  [_vm._v("delete")]
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c(
        "md-button",
        {
          nativeOn: {
            click: function ($event) {
              return _vm.add.apply(null, arguments)
            },
          },
        },
        [_vm._v("매핑 추가")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }