import { render, staticRenderFns } from "./BpmnModel.vue?vue&type=template&id=2d3bf277&xmlns%3Av-on=http%3A%2F%2Fwww.w3.org%2F1999%2Fxhtml"
import script from "./BpmnModel.vue?vue&type=script&lang=js"
export * from "./BpmnModel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/kibumpark/Desktop/uEngine-project/platform/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2d3bf277')) {
      api.createRecord('2d3bf277', component.options)
    } else {
      api.reload('2d3bf277', component.options)
    }
    module.hot.accept("./BpmnModel.vue?vue&type=template&id=2d3bf277&xmlns%3Av-on=http%3A%2F%2Fwww.w3.org%2F1999%2Fxhtml", function () {
      api.rerender('2d3bf277', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/designer/bpmnModeling/BpmnModel.vue"
export default component.exports