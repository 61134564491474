import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    { staticClass: "px-3 py-3 my-2", attrs: { outlined: "" } },
    [
      _vm.parameterContexts.length > 0
        ? _c(
            VCardText,
            _vm._l(_vm.parameterContexts, function (parameterContext, idx) {
              return _c(
                "div",
                { key: idx },
                [
                  _c(
                    VRow,
                    [
                      _vm.forSubProcess
                        ? _c(
                            VCol,
                            { attrs: { cols: "4" } },
                            [
                              _vm.calleeDefinition
                                ? _c(VSelect, {
                                    attrs: {
                                      items:
                                        _vm.calleeDefinition
                                          .processVariableDescriptors,
                                      "item-text": "name",
                                      "item-value": "name",
                                      label: "피호출측 변수",
                                    },
                                    model: {
                                      value: parameterContext.argument.text,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          parameterContext.argument,
                                          "text",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "parameterContext.argument.text",
                                    },
                                  })
                                : _c(VTextField, {
                                    attrs: { label: "input", id: "input" },
                                    model: {
                                      value: parameterContext.argument.text,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          parameterContext.argument,
                                          "text",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "parameterContext.argument.text",
                                    },
                                  }),
                            ],
                            1
                          )
                        : _c(
                            VCol,
                            { attrs: { cols: "4" } },
                            [
                              _c(VTextField, {
                                attrs: {
                                  name: "input",
                                  id: "input",
                                  label: "아규먼트",
                                },
                                model: {
                                  value: parameterContext.argument.text,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      parameterContext.argument,
                                      "text",
                                      $$v
                                    )
                                  },
                                  expression: "parameterContext.argument.text",
                                },
                              }),
                            ],
                            1
                          ),
                      parameterContext.transformerMapping
                        ? _c(
                            VCol,
                            { attrs: { cols: "3" } },
                            [
                              _c(VSelect, {
                                staticStyle: { "min-width": "20px" },
                                attrs: {
                                  items: _vm.transformerList,
                                  label: "변환",
                                },
                                model: {
                                  value:
                                    parameterContext.transformerMapping
                                      .transformer,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      parameterContext.transformerMapping,
                                      "transformer",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "parameterContext.transformerMapping.transformer",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        VCol,
                        { attrs: { cols: "2" } },
                        [
                          _c(VSelect, {
                            staticStyle: { "min-width": "20px" },
                            attrs: {
                              items: _vm.connectDirections,
                              label: "연결방향",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.directionChanged(parameterContext)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "selection",
                                  fn: function () {
                                    return [
                                      _c(VIcon, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.iconForDirection(
                                              parameterContext.direction
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "item",
                                  fn: function (data) {
                                    return [
                                      data.item != "OUT "
                                        ? _c(
                                            "div",
                                            [
                                              _c(VIcon, [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.iconForDirection(
                                                      data.item
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            [
                                              _c(VIcon, [
                                                _vm._v("all_inclusive"),
                                              ]),
                                              _c(VIcon, [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.iconForDirection(
                                                      data.item
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: parameterContext.direction,
                              callback: function ($$v) {
                                _vm.$set(parameterContext, "direction", $$v)
                              },
                              expression: "parameterContext.direction",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "5" } },
                        [
                          _c("bpmn-variable-selector", {
                            attrs: { definition: _vm.value },
                            model: {
                              value: parameterContext.variable,
                              callback: function ($$v) {
                                _vm.$set(parameterContext, "variable", $$v)
                              },
                              expression: "parameterContext.variable",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "1" } },
                        [
                          _c(
                            VBtn,
                            {
                              staticClass: "px-0",
                              attrs: { icon: "", color: "grey" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeMapping(parameterContext)
                                },
                              },
                            },
                            [_c(VIcon, [_vm._v("delete")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _c(
        VCardActions,
        [
          _c(VSpacer),
          _c(
            VBtn,
            {
              staticClass: "my-3",
              attrs: { text: "", color: "primary" },
              on: { click: _vm.addMapping },
            },
            [_vm._v("\n            Add Mapping\n        ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }