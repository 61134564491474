import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import ClickOutside from 'vuetify/lib/directives/click-outside';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          def: ClickOutside,
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.close,
          expression: "close",
        },
      ],
    },
    [
      _vm.bpmnComponent
        ? _c(
            VCard,
            { staticClass: "mx-auto", attrs: { outlined: "" } },
            [
              _c(
                VCardTitle,
                {
                  staticClass: "text--primary px-0 py-2",
                  staticStyle: { "border-bottom": "1px solid grey" },
                },
                [
                  _vm.bpmnComponent.type == "Task" ||
                  _vm.bpmnComponent.type == "SubProcess"
                    ? _c("div", [
                        _c("span", {
                          staticClass: "icons bpmn-icon-parallel-mi-marker",
                        }),
                        _c("span", {
                          staticClass: "icons bpmn-icon-sequential-mi-marker",
                        }),
                        _c("span", {
                          staticClass: "icons bpmn-icon-loop-marker",
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "icons bpmn-icon-close",
                            staticStyle: {
                              "padding-left": "79px",
                              "margin-right": "10px",
                            },
                            on: { click: _vm.close },
                          },
                          [
                            _c(VIcon, { on: { click: _vm.close } }, [
                              _vm._v("clear"),
                            ]),
                          ],
                          1
                        ),
                      ])
                    : _c("div", [
                        _c("span", {
                          staticClass: "icons bpmn-icon-parallel-mi-marker",
                        }),
                        _c("span", {
                          staticClass: "icons bpmn-icon-sequential-mi-marker",
                        }),
                        _c("span", {
                          staticClass: "icons bpmn-icon-loop-marker",
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "icons bpmn-icon-close",
                            staticStyle: {
                              "padding-left": "79px",
                              "margin-right": "10px",
                            },
                            on: { click: _vm.close },
                          },
                          [
                            _c(VIcon, { on: { click: _vm.close } }, [
                              _vm._v("clear"),
                            ]),
                          ],
                          1
                        ),
                      ]),
                ]
              ),
              _c(
                VCardText,
                { staticClass: "text--primary px-0 py-1" },
                _vm._l(_vm.items, function (item, idx) {
                  return _c(
                    "p",
                    {
                      key: idx,
                      staticClass: "icons",
                      class: item.icon,
                      staticStyle: { cursor: "default" },
                      on: {
                        click: function ($event) {
                          return _vm.change(item.component)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "icon-text" }, [
                        _vm._v(_vm._s(item.label)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }