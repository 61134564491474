var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "geometry-element",
        {
          attrs: {
            selectable: "",
            movable: "",
            resizable: "",
            connectable: "",
            deletable: "",
            id: _vm.value.tracingTag,
            x: _vm.value.elementView.x,
            y: _vm.value.elementView.y,
            width: _vm.value.elementView.width,
            height: _vm.value.elementView.height,
            _style: _vm.style,
            parentId: _vm.value.elementView.parent,
            label: _vm.namePanel,
            customMoveActionExist: _vm.canvas.isCustomMoveExist,
          },
          on: {
            "update:id": function ($event) {
              return _vm.$set(_vm.value, "tracingTag", $event)
            },
            "update:x": function ($event) {
              return _vm.$set(_vm.value.elementView, "x", $event)
            },
            "update:y": function ($event) {
              return _vm.$set(_vm.value.elementView, "y", $event)
            },
            "update:width": function ($event) {
              return _vm.$set(_vm.value.elementView, "width", $event)
            },
            "update:height": function ($event) {
              return _vm.$set(_vm.value.elementView, "height", $event)
            },
            "update:_style": function ($event) {
              _vm.style = $event
            },
            "update:parentId": function ($event) {
              return _vm.$set(_vm.value.elementView, "parent", $event)
            },
            "update:parent-id": function ($event) {
              return _vm.$set(_vm.value.elementView, "parent", $event)
            },
            "update:label": function ($event) {
              _vm.namePanel = $event
            },
            customMoveAction: _vm.delayedMove,
            moveShape: _vm.onMoveShape,
            dblclick: _vm.showProperty,
            selectShape: function ($event) {
              _vm.closeComponentChanger()
              _vm.selectedActivity()
            },
            deSelectShape: function ($event) {
              _vm.closeComponentChanger()
              _vm.deSelectedActivity()
            },
            removeShape: _vm.onRemoveShape,
            redrawShape: _vm.closeComponentChanger,
            addedToGroup: _vm.onAddedToGroup,
          },
        },
        [
          _c("geometry-polygon", {
            attrs: {
              vertices: [
                [0, 50],
                [50, 100],
                [100, 50],
                [50, 0],
              ],
            },
          }),
          _c("geometry-line", {
            attrs: {
              from: [20, 50],
              to: [80, 50],
              _style: { "stroke-width": 5 },
            },
          }),
          _c("geometry-line", {
            attrs: {
              from: [50, 20],
              to: [50, 80],
              _style: { "stroke-width": 5 },
            },
          }),
          _c(
            "sub-elements",
            [
              _c("bpmn-state-animation", {
                attrs: { status: _vm.status, type: _vm.type },
              }),
            ],
            1
          ),
          _c(
            "sub-elements",
            [
              _c("multi-user-status-indicator", {
                attrs: {
                  images: _vm.newEditUserImg,
                  "element-height": _vm.value.elementView.height,
                },
              }),
            ],
            1
          ),
          _c("bpmn-sub-controller", { attrs: { type: _vm.type } }),
        ],
        1
      ),
      _vm.drawer
        ? _c("bpmn-gateway-panel", {
            on: { close: _vm.closePanel },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }