import { VCard } from 'vuetify/lib/components/VCard';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        VCard,
        {
          staticStyle: {
            "box-shadow":
              "0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12)",
          },
          attrs: { draggable: "true", _drag: "drag" },
          nativeOn: {
            dragstart: function ($event) {
              return _vm.dragover.apply(null, arguments)
            },
            dragleave: function ($event) {
              return _vm.onDragLeave.apply(null, arguments)
            },
            dragenter: function ($event) {
              return _vm.onDragenter.apply(null, arguments)
            },
          },
        },
        [
          _c("div", [
            _vm.value.type == "loopCount" ||
            _vm.value._type == "org.uengine.five.kernel.LoopCountEvaluate"
              ? _c(
                  "div",
                  { attrs: { id: "processVal" } },
                  [
                    _vm.definition && _vm.definition.elements
                      ? _c(VSelect, {
                          attrs: {
                            items: _vm.elements,
                            "item-text": "tracingTag",
                            "item-value": "name.text",
                            id: "input",
                            label: _vm.$t("loopCountOf"),
                          },
                          model: {
                            value: _vm.value.pv.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.value.pv, "name", $$v)
                            },
                            expression: "value.pv.name",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _c(
                  "div",
                  { attrs: { id: "processVal" } },
                  [
                    _vm.definition && _vm.definition.processVariableDescriptors
                      ? _c(VSelect, {
                          attrs: {
                            items: _vm.definition.processVariableDescriptors,
                            "item-text": "name",
                            "item-value": "name",
                            id: "input",
                            label: _vm.$t("variable"),
                          },
                          model: {
                            value: _vm.value.pv.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.value.pv, "name", $$v)
                            },
                            expression: "value.pv.name",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
            _c(
              "div",
              { attrs: { id: "dataCon" } },
              [
                _c(VSelect, {
                  attrs: { items: _vm.comparatorList, label: "Comparator" },
                  on: { change: _vm.conditionChanged },
                  model: {
                    value: _vm.value.condition,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "condition", $$v)
                    },
                    expression: "value.condition",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { attrs: { id: "dataVal" } },
              [
                _c(VTextField, {
                  attrs: { label: "Value" },
                  model: {
                    value: _vm.value.val,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "val", $$v)
                    },
                    expression: "value.val",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }