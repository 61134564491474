var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("text-element", {
        attrs: {
          selectable: "",
          id: _vm.value.name,
          x: 50,
          y: _vm.y,
          width: 50,
          text: _vm.value.name,
          _style: {
            "font-color": "grey",
            "text-anchor": "start",
          },
        },
        on: {
          "update:id": function ($event) {
            return _vm.$set(_vm.value, "name", $event)
          },
          "update:y": function ($event) {
            _vm.y = $event
          },
        },
      }),
      _c("circle-element", {
        attrs: {
          x: 100,
          y: _vm.y,
          width: 7,
          height: 7,
          selectable: "",
          connectable: "",
          draggable: "",
          movable: "",
        },
        on: {
          "update:y": function ($event) {
            _vm.y = $event
          },
          selectShape: function ($event) {
            return _vm.handleClick($event)
          },
        },
      }),
      _c("bpmn-mapper-context-menu", {
        ref: "vueSimpleContextMenu",
        attrs: { elementId: _vm.value.name, options: _vm.menuList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }