import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VLayout,
    { attrs: { wrap: "" } },
    [
      _c(
        VNavigationDrawer,
        {
          staticStyle: { width: "600px" },
          attrs: { absolute: "", permanent: "", right: "" },
        },
        [
          _c(
            VList,
            { staticClass: "pa-1" },
            [
              _c(
                VListItem,
                [
                  _c(
                    VTabs,
                    {
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    _vm._l(_vm.tabs, function (tab) {
                      return _c(
                        VTab,
                        {
                          key: tab,
                          attrs: { id: tab.toLowerCase() + _vm._uid },
                        },
                        [_c(VListItemTitle, [_vm._v(_vm._s(tab))])],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: { icon: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [
                      _c(VIcon, { attrs: { color: "grey lighten-1" } }, [
                        _vm._v("mdi-close"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.value._instanceInfo
            ? _c(VSimpleTable, {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function () {
                        return [
                          _c("thead", [
                            _c("tr", [
                              _c("th", { staticClass: "text-left" }, [
                                _vm._v("Key"),
                              ]),
                              _c("th", { staticClass: "text-left" }, [
                                _vm._v("Value"),
                              ]),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(
                              _vm.value._instanceInfo,
                              function (row, index) {
                                return _c("tr", { key: index }, [
                                  _c("td", [_vm._v(_vm._s(index))]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm.value._instanceInfo[index])
                                    ),
                                  ]),
                                ])
                              }
                            ),
                            0
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1701337627
                ),
              })
            : _vm._e(),
          _c(
            VList,
            { staticClass: "pt-0", attrs: { dense: "", flat: "" } },
            [
              _c(VDivider),
              _vm.tab == 0
                ? _c(
                    "div",
                    { staticStyle: { "margin-top": "10px" } },
                    [
                      _c(
                        VCard,
                        { attrs: { flat: "" } },
                        [
                          _c(
                            VCardText,
                            [
                              _vm.value.tracingTag !== null &&
                              _vm.value.tracingTag !== undefined
                                ? _c(VTextField, {
                                    attrs: {
                                      label: "액티비티 ID*",
                                      counter: "50",
                                    },
                                    model: {
                                      value: _vm.value.tracingTag,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.value, "tracingTag", $$v)
                                      },
                                      expression: "value.tracingTag",
                                    },
                                  })
                                : _vm._e(),
                              _vm._t("name-panel", function () {
                                return [
                                  _c(VTextField, {
                                    attrs: {
                                      label: "액티비티 명",
                                      autofocus: "",
                                    },
                                    model: {
                                      value: _vm.value.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.value, "name", $$v)
                                      },
                                      expression: "value.name",
                                    },
                                  }),
                                ]
                              }),
                              _vm._t("edit-property"),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tab == 1
                ? _c(
                    "div",
                    [
                      _c(
                        VCard,
                        { attrs: { flat: "" } },
                        [
                          _c(
                            VCardText,
                            [
                              _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c(VTextField, {
                                        attrs: { label: "x", type: "number" },
                                        model: {
                                          value: _vm.value.elementView.x,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.value.elementView,
                                              "x",
                                              $$v
                                            )
                                          },
                                          expression: "value.elementView.x",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c(VTextField, {
                                        attrs: { label: "y", type: "number" },
                                        model: {
                                          value: _vm.value.elementView.y,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.value.elementView,
                                              "y",
                                              $$v
                                            )
                                          },
                                          expression: "value.elementView.y",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c(VTextField, {
                                        attrs: {
                                          label: "width",
                                          type: "number",
                                        },
                                        model: {
                                          value: _vm.value.elementView.width,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.value.elementView,
                                              "width",
                                              $$v
                                            )
                                          },
                                          expression: "value.elementView.width",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c(VTextField, {
                                        attrs: {
                                          label: "height",
                                          type: "number",
                                        },
                                        model: {
                                          value: _vm.value.elementView.height,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.value.elementView,
                                              "height",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "value.elementView.height",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._l(_vm.style, function (item, idx) {
                                return _c(VTextField, {
                                  key: idx,
                                  attrs: { label: item.key },
                                  model: {
                                    value: _vm.style[idx].value,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.style[idx], "value", $$v)
                                    },
                                    expression: "style[idx].value",
                                  },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._t("edit-tab"),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }