var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("edge-element", {
        attrs: {
          selectable: "",
          connectable: "",
          deletable: "",
          id: _vm.relation.sourceRef + "-" + _vm.relation.targetRef,
          vertices: _vm.vertices,
          from: _vm.relation.sourceRef,
          to: _vm.relation.targetRef,
          _style: _vm.style,
          label: _vm.relation.name,
          customMoveActionExist: _vm.canvas.isCustomMoveExist,
        },
        on: {
          "update:vertices": function ($event) {
            _vm.vertices = $event
          },
          "update:from": function ($event) {
            return _vm.$set(_vm.relation, "sourceRef", $event)
          },
          "update:to": function ($event) {
            return _vm.$set(_vm.relation, "targetRef", $event)
          },
          "update:_style": function ($event) {
            _vm.style = $event
          },
          "update:label": function ($event) {
            return _vm.$set(_vm.relation, "name", $event)
          },
          customRelationMoveAction: _vm.delayedRelationMove,
          dblclick: _vm.showProperty,
          selectShape: function ($event) {
            _vm.closeComponentChanger()
            _vm.selectedFlow()
          },
          deSelectShape: function ($event) {
            _vm.closeComponentChanger()
            _vm.deSelectedFlow()
          },
          removeShape: _vm.onRemoveShape,
          redrawShape: _vm.closeComponentChanger,
          addedToGroup: _vm.onAddedToGroup,
        },
      }),
      _vm.drawer
        ? _c("bpmn-relation-panel", {
            attrs: { item: _vm.relation, otherwise: _vm.otherwise },
            on: {
              "update:item": function ($event) {
                _vm.relation = $event
              },
              "update:otherwise": function ($event) {
                _vm.otherwise = $event
              },
              close: _vm.closePanel,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }