var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("edge-element", {
        attrs: {
          selectable: "",
          connectable: "",
          deletable: "",
          id: _vm.value.sourceRef + "-" + _vm.value.targetRef,
          vertices: _vm.vertices,
          from: _vm.value.sourceRef,
          to: _vm.value.targetRef,
          _style: {
            "stroke-width": "1.3",
            "stroke-dasharray": "- ",
            marker: {
              end: {
                id: "OG.marker.MessageFlowArrowStartMarker",
                size: [8, 6],
              },
              start: {
                id: "OG.marker.MessageFlowArrowEndMarker",
                size: [6, 6],
              },
            },
          },
          label: _vm.namePanel,
          customMoveActionExist: _vm.canvas.isCustomMoveExist,
        },
        on: {
          "update:vertices": function ($event) {
            _vm.vertices = $event
          },
          "update:from": function ($event) {
            return _vm.$set(_vm.value, "sourceRef", $event)
          },
          "update:to": function ($event) {
            return _vm.$set(_vm.value, "targetRef", $event)
          },
          "update:label": function ($event) {
            _vm.namePanel = $event
          },
          customRelationMoveAction: _vm.delayedRelationMove,
          dblclick: _vm.showProperty,
          selectShape: function ($event) {
            _vm.closeComponentChanger()
            _vm.selectedFlow()
          },
          deSelectShape: function ($event) {
            _vm.closeComponentChanger()
            _vm.deSelectedFlow()
          },
          removeShape: _vm.onRemoveShape,
          redrawShape: _vm.closeComponentChanger,
          addedToGroup: _vm.onAddedToGroup,
        },
      }),
      _vm.drawer
        ? _c("bpmn-relation-panel", {
            attrs: { otherwise: _vm.otherwise },
            on: { close: _vm.closePanel },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }