var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "md-list-item",
    _vm._l(_vm.trees, function (item) {
      return _c(
        "div",
        { staticClass: "tree-list-style" },
        [
          _vm._v("\n        - "),
          _c(
            "span",
            {
              class: { "list-hover": item.name == _vm.selectedItem },
              on: {
                mouseover: function ($event) {
                  _vm.selectedItem = item.name
                },
                mouseout: function ($event) {
                  _vm.selectedItem = ""
                },
                click: function ($event) {
                  return _vm.onClickList(item)
                },
              },
            },
            [_vm._v("\n            " + _vm._s(item.name) + "\n        ")]
          ),
          _c(
            "md-list",
            { staticClass: "tree-list" },
            [_c("bpmn-tree-list", { attrs: { trees: item.children } })],
            1
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }