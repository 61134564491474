import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "bpmn-common-panel",
    {
      attrs: {
        image: _vm.image,
        "is-read-only": _vm.isReadOnly,
        "width-style": _vm.widthStyle,
      },
      on: { close: _vm.closePanel },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "template",
        { slot: "edit-property" },
        [
          _vm.value._type == "org.uengine.kernel.bpmn.EndEvent"
            ? _c(VSwitch, {
                attrs: { label: "Stops all running tokens" },
                model: {
                  value: _vm.value.stopAllTokens,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "stopAllTokens", $$v)
                  },
                  expression: "value.stopAllTokens",
                },
              })
            : _vm._e(),
          _c(VTextField, {
            attrs: { label: "retryDelay", type: "number" },
            model: {
              value: _vm.value.retryDelay,
              callback: function ($$v) {
                _vm.$set(_vm.value, "retryDelay", $$v)
              },
              expression: "value.retryDelay",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }