import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        VDialog,
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.deleteDialog,
            callback: function ($$v) {
              _vm.deleteDialog = $$v
            },
            expression: "deleteDialog",
          },
        },
        [
          _c(
            VCard,
            [
              _c(VCardTitle, [_vm._v(_vm._s(_vm.dc.title))]),
              _c(VCardText, [_vm._v(_vm._s(_vm.dc.contentHtml))]),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    {
                      attrs: { text: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.deleteDialog = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.dc.cancel))]
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: { text: "", color: "primary" },
                      on: { click: _vm.deleteVariable },
                    },
                    [_vm._v(_vm._s(_vm.dc.ok))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(VDataTable, {
        attrs: {
          headers: _vm.dataHeaders,
          items: _vm.value,
          "items-per-page": 5,
          "item-key": "name",
          "show-select": "",
          "hide-default-header": _vm.selected.length > 0,
        },
        on: { "dblclick:row": _vm.editForm },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function ({ dataHeaders }) {
              return [
                _vm.selected.length > 0
                  ? _c("thead", { staticClass: "v-data-table-header" }, [
                      _c("tr", [
                        _c(
                          "th",
                          {
                            staticClass: "text-start",
                            attrs: { colspan: "6" },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.selected.length) + " selected"),
                            ]),
                          ]
                        ),
                        _c(
                          "th",
                          [
                            _c(
                              VBtn,
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.deleteDialog = true
                                  },
                                },
                              },
                              [_c(VIcon, [_vm._v("delete")])],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
      _vm.fullFledged
        ? _c(
            "div",
            [
              _c(
                VBtn,
                {
                  attrs: { text: "", color: "primary" },
                  on: { click: _vm.newForm },
                },
                [
                  _c(VIcon, [_vm._v("add")]),
                  _vm._v("\n            Add\n        "),
                ],
                1
              ),
              _c(
                VDialog,
                {
                  attrs: { "max-width": "400" },
                  model: {
                    value: _vm.updateDialogEnabled,
                    callback: function ($$v) {
                      _vm.updateDialogEnabled = $$v
                    },
                    expression: "updateDialogEnabled",
                  },
                },
                [
                  _c(
                    VCard,
                    [
                      _c(VCardTitle, [_vm._v("Edit")]),
                      _c(
                        VCardText,
                        [
                          _vm.updateDialogEnabled
                            ? _c("bpmn-object-form", {
                                attrs: {
                                  formData: _vm.formData,
                                  categories: _vm.categories,
                                  classList: _vm.classList,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        VCardActions,
                        [
                          _c(VSpacer),
                          _c(
                            VBtn,
                            {
                              attrs: { color: "primary", text: "" },
                              on: {
                                click: function ($event) {
                                  _vm.updateDialogEnabled = false
                                },
                              },
                            },
                            [_vm._v("Close")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        VDialog,
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.addDialogEnabled,
            callback: function ($$v) {
              _vm.addDialogEnabled = $$v
            },
            expression: "addDialogEnabled",
          },
        },
        [
          _c(
            VCard,
            [
              _c(VCardTitle, [_vm._v("New")]),
              _c(
                VCardText,
                [
                  _vm.addDialogEnabled
                    ? _c("bpmn-object-form", {
                        attrs: {
                          formData: _vm.formData,
                          categories: _vm.categories,
                          classList: _vm.classList,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    {
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.enterForm },
                    },
                    [_vm._v("Enter")]
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.addDialogEnabled = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }