import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(VTextField, {
        attrs: { label: "Name" },
        model: {
          value: _vm.value.name,
          callback: function ($$v) {
            _vm.$set(_vm.value, "name", $$v)
          },
          expression: "value.name",
        },
      }),
      _c(VTextField, {
        attrs: { label: "Display Name" },
        model: {
          value: _vm.value.displayName.text,
          callback: function ($$v) {
            _vm.$set(_vm.value.displayName, "text", $$v)
          },
          expression: "value.displayName.text",
        },
      }),
      _c(VTextField, {
        attrs: { label: "Default Value In String" },
        model: {
          value: _vm.value.defaultValueInString,
          callback: function ($$v) {
            _vm.$set(_vm.value, "defaultValueInString", $$v)
          },
          expression: "value.defaultValueInString",
        },
      }),
      _c("label", [_vm._v("Global")]),
      _c(VSwitch, {
        attrs: { label: "Yes" },
        model: {
          value: _vm.value.global,
          callback: function ($$v) {
            _vm.$set(_vm.value, "global", $$v)
          },
          expression: "value.global",
        },
      }),
      _c(VSelect, {
        attrs: { items: _vm.options, label: "Persist Option" },
        model: {
          value: _vm.value.persistOption,
          callback: function ($$v) {
            _vm.$set(_vm.value, "persistOption", $$v)
          },
          expression: "value.persistOption",
        },
      }),
      _c("label", [_vm._v("변수 유형")]),
      _c(VSelect, {
        attrs: {
          items: _vm.categories,
          "item-text": "displayName",
          "item-value": "value",
          label: "Category",
        },
        model: {
          value: _vm.category,
          callback: function ($$v) {
            _vm.category = $$v
          },
          expression: "category",
        },
      }),
      _vm.category == "primitive"
        ? _c(VSelect, {
            attrs: {
              items: _vm.primitiveTypes,
              "item-text": "displayName",
              "item-value": "className",
              label: "Class",
            },
            model: {
              value: _vm.classValue,
              callback: function ($$v) {
                _vm.classValue = $$v
              },
              expression: "classValue",
            },
          })
        : _vm.category == "class"
        ? _c(VSelect, {
            attrs: {
              items: _vm.classList,
              "item-text": "name",
              "item-value": "name",
              label: "Class",
            },
            model: {
              value: _vm.classValue,
              callback: function ($$v) {
                _vm.classValue = $$v
              },
              expression: "classValue",
            },
          })
        : _c(VSelect, {
            attrs: { label: "Class" },
            model: {
              value: _vm.classValue,
              callback: function ($$v) {
                _vm.classValue = $$v
              },
              expression: "classValue",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }